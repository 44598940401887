import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { OfferForm } from '../../../core/models/forms.model';
import {
  OfferDetails,
  Offer,
  ProjectStep
} from '../../../core/models/offers.model';
import { ListApiResponse } from '../../../core/models/global.model';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getProviderOffers(page: number): Observable<ListApiResponse<Offer>> {
    const url = `${this.apiUrl}/bidding/response?page=${page}`;
    return this.http.get<ListApiResponse<Offer>>(url);
  }

  public sendOffer(data: OfferForm): Observable<void> {
    const url = `${this.apiUrl}/bidding/response`;
    return this.http.post<void>(url, data);
  }

  public getOfferDetails(id: string): Observable<OfferDetails> {
    const url = `${this.apiUrl}/bidding/response/${id}`;
    return this.http.get<OfferDetails>(url);
  }

  public getOfferLogs(id: string): Observable<ProjectStep[]> {
    const url = `${this.apiUrl}/bidding/response/${id}/logs`;
    return this.http.get<ProjectStep[]>(url);
  }

  public acceptOffer(id: number): Observable<void> {
    const url = `${this.apiUrl}/bidding/response/${id}/evaluate`;
    const data = { accept: true };
    return this.http.post<void>(url, data);
  }

  public rejectOffer(id: number): Observable<void> {
    const url = `${this.apiUrl}/bidding/response/${id}/evaluate`;
    const data = { accept: false };
    return this.http.post<void>(url, data);
  }

  public changeStatus(
    offerId: number,
    data: { action: string; step_id: number }
  ): Observable<void> {
    const url = `${this.apiUrl}/bidding/response/${offerId}/change-status`;
    return this.http.post<void>(url, data);
  }
}
