import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserFile } from '../../../core/models/user.model';
import { FilesService } from '../../../core/services/files.service';
import { FileObject } from '../../../core/models/global.model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() singleFile?: boolean;
  @Input() disabled?: boolean;
  @Input() loading?: boolean;
  @Input() autoUpload?: boolean;
  @Input() endpoint!: string;
  @Input() kind!: UserFile;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() buttonLabel = 'UPLOAD-FILES';
  @Input() invalid?: boolean;
  @Input() invalidTxt?: string;
  @Input() hideCaption?: boolean;
  @Input() ghostFiles: FileObject[] = [];
  @Input() ghostDelete?: boolean;
  @Output() onSelect = new EventEmitter<File[]>();
  @Output() onUpload = new EventEmitter<{ id: number }>();
  @Output() onMultiUpload = new EventEmitter<number[]>();
  @Output() onDelete = new EventEmitter<number>(); // returns the Id of the deleted file

  @ViewChild('fileInput') fileInput!: ElementRef;

  private static id = 0;
  private touched = false;

  public onChange = (value: any) => {};
  public onTouched = () => {};

  public inputId: string;
  public files: File[] = [];
  public uploadedFiles: { file: File; id: number }[] = [];

  constructor(private filesService: FilesService) {
    this.inputId = 'upload_input' + FileUploadComponent.id++;
  }

  public onFileSelected(event: any): void {
    if (this.singleFile && Object.keys(event.target.files).length)
      this.files = [];
    const selectedFiles: FileList = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles.item(i) as File;
      if (this.isValidFile(file)) {
        this.files.push(file);
      }
    }
    // this.onChange(this.files);
    this.onSelect.emit(this.files);

    if (this.autoUpload && this.endpoint) this.uploadFiles(this.files);
    else this.onChange(this.files);
  }

  public deleteFile(file: File): void {
    this.startLoading()
    const i = this.files.findIndex(
      (f) =>
        f.lastModified === file.lastModified &&
        f.name === file.name &&
        f.size === file.size
    );
    if (!this.ghostDelete) {
      const id = this.uploadedFiles[i].id;
      this.filesService.deleteFile(id, 'bidding/request-media').subscribe({
        next: () => {
          this.ghostDeleteUploadedFile(file, i);
          this.onMultiUpload.emit(this.uploadedFiles.map((f) => f.id));
          this.stopLoading()
        },
        error: () => this.stopLoading(), // handle error
      });
    } else this.ghostDeleteUploadedFile(file, i);
  }

  private startLoading(): void {
    this.loading = true;
    this.disabled = true;
  }
  private stopLoading(): void {
    this.loading = false;
    this.disabled = false;
  }

  private ghostDeleteUploadedFile(file: File, i: number): void {
    this.files.splice(i, 1);
    const idx = this.uploadedFiles.findIndex(
      (f) =>
        f.file.lastModified === file.lastModified &&
        f.file.name === file.name &&
        f.file.size === file.size
    );
    this.uploadedFiles.splice(idx, 1);
    this.onMultiUpload.emit(this.allFilesIds)
    this.stopLoading()
  }

  private get allFilesIds(): number[] {
    const files = [...this.uploadedFiles.map(f => f.id), ...this.ghostFiles?.map(f => f.id)];
    const filesWithoutDuplicates: number[] = Array.from(new Set(files));
    return filesWithoutDuplicates;
  } 

  public deleteGhostFile(file: FileObject, idx: number): void {
    this.ghostFiles.splice(idx, 1)
    this.onMultiUpload.emit(this.allFilesIds)
  }
  
  private isValidFile(file: File): boolean {
    const fileType = file.type;
    const isImage = fileType === 'image/png' || fileType === 'image/jpeg';
    const isPdf = file.type === 'application/pdf';
    const isSizeValid = file.size <= 5000000; // 5MB in bytes
    const isNotRepeated =
      this.files.findIndex(
        (f) =>
          f.lastModified === file.lastModified &&
          f.name === file.name &&
          f.size === file.size
      ) === -1;

    return (isImage || isPdf) && isSizeValid && isNotRepeated;
  }

  public getImgUrl(file: File): string | null {
    return file.type.startsWith('image') ? URL.createObjectURL(file) : null;
  }

  private uploadFiles(files: File[]): void {
    const ids: number[] = [];
    this.startLoading()
    let i = 0;
    files.forEach((file) => {
      i++;
      if (
        this.uploadedFiles.find(
          (f) =>
            f.file.lastModified === file.lastModified &&
            f.file.name === file.name &&
            f.file.size === file.size
        )
      ) {
        this.stopLoading()
      } else {
        const data = new FormData();
        data.append('file', file, file?.name);
        if (this.kind) data.append('kind', this.kind);
        this.filesService.uploadFile(data, this.endpoint).subscribe({
          next: (res: { id: number }) => {
            this.uploadedFiles.push({ file, id: res.id });
            ids.push(res.id);
              this.markAsTouched();
              if (this.singleFile) this.onUpload.emit(res);
              else this.onMultiUpload.emit(this.allFilesIds); 
              this.stopLoading()
          },
          error: () => {
            // TODO: handle error error handle
          },
        });
      }
    });
  }

  // implement ControlValueAccessor methods

  writeValue(value: any): void {
    // Not needed for file upload
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Not needed for file upload
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  resetInputCachedValue(): void {
    // clear cached event of files select, to make sure event fires every single time
    this.fileInput.nativeElement.value = ''
  }
}
