import { Component } from '@angular/core';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'app-empty-home-page',
  templateUrl: './empty-home-page.component.html',
  styleUrl: './empty-home-page.component.scss',
})
export class EmptyHomePageComponent {
  public isCustomer: boolean;

  constructor(userService: UserService) {
    this.isCustomer = userService.getUserInfo()?.role === 'customer'
  }
}
