import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(private http: HttpClient) {}

  public uploadFile(data: any, endpoint: string): Observable<{ id: number }> {
    const url = `${environment.apiUrl}/${endpoint}`;
    return this.http.post<{ id: number }>(url, data);
  }

  public updateFile(
    id: number,
    endpoint: string,
    data: FormData
  ): Observable<string> {
    const url = `${environment.apiUrl}/${endpoint}/${id}`;
    return this.http.put<string>(url, data);
  }

  public deleteFile(id: number, endpoint: string): Observable<string> {
    const url = `${environment.apiUrl}/${endpoint}/${id}`;
    return this.http.delete<string>(url);
  }

  public downloadFile(fileUrl: string, fileName: string): void {
    this.http
      .get(fileUrl, { responseType: 'blob' })
      .subscribe((response: Blob) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(response);
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  }

  public isValidFile(file: File): boolean {
    const fileType = file.type;
    const isImage = fileType === 'image/png' || fileType === 'image/jpeg';
    const isPdf = file.type === 'application/pdf';
    const isSizeValid = file.size <= 5242880; // 5MB in bytes

    return (isImage || isPdf) && isSizeValid;
  }
}
