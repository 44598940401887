<form autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="otp-input-group" [ngClass]="{ disabled: loading, invalid }">
    <input
      numbersOnly
      *ngFor="let input of form.controls | keyvalue"
      [id]="input.key"
      cdsText
      class="inpt-one-digit"
      size="lg"
      type="tel"
      maxlength="1"
      [formControlName]="input.key"
      [disabled]="loading"
      (input)="onInput(input, $event)"
      (keydown.Backspace)="onBackspace(input)"
    />
  </div>
</form>

<p *ngIf="invalid" class="txt-caption txt-caption-danger">
  {{ 'INVALID-OTP' | translate }}
</p>

<p *ngIf="otpTimeout > 0" class="txt-caption timeout-txt">
  {{ "LOGIN.RESEND-OTP-MSG" | translate : { seconds: otpTimeout } }}
</p>

<p *ngIf="otpTimeout == 0" class="resend-otp link" (click)="resend()">{{ 'RESEND' | translate }}</p>
