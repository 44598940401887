import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User, UserToken } from '../models/user.model';
import { Router } from '@angular/router';
import { UserRegistrationForm } from '../models/forms.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private router: Router) {}

  //check if number is existed on the system
  public checkIfNumberExisted(phone_number: string): Observable<{result: boolean}> {
    const url = `${this.apiUrl}/auth/check-phone-number?phone_number=${phone_number}`;
    return this.http.get<{result: boolean}>(url);
  }

  // request OTP for phone number verification
  public requestOtp(phone_number: string): Observable<string> {
    const url = `${this.apiUrl}/auth/otp?phone_number=${phone_number}`;
    return this.http.get<string>(url);
  }

  // login & get user token
  public login(data: { phone_number: string; code: string }): Observable<User> {
    const url = `${this.apiUrl}/auth/token`;
    return this.http.post<User>(url, data);
  }

  // refresh token
  public updateToken(token: string): Observable<UserToken> {
    const url = `${this.apiUrl}/auth/refresh`;
    return this.http.post<UserToken>(url, token);
  }

  // user registration
  public register(data: UserRegistrationForm): Observable<User> {
    const url = `${this.apiUrl}/auth/register`;
    return this.http.post<User>(url, data);
  }

  /* ============ */
  /* AUTH_METHODS */
  /* ============ */
  public isLoggedIn(): boolean {
    // check if user has a token
    const token = localStorage.getItem('token');
    if (token) return true;
    else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }

  public isLoggedOut(): boolean {
    // check if user doesn't have token
    const token = localStorage.getItem('token');
    if (!token) return true;
    else {
      this.router.navigateByUrl('/');
      return false;
    }
  }

  public canActivateOrders(): boolean {
    let role;
    const user = localStorage.getItem('userInfo');
    if (user) role = JSON.parse(user).role;
    if (role === 'customer') return true;
    else {
      this.router.navigateByUrl('/');
      return false;
    }
  }

  public logOut(): void {
    const lang = localStorage.getItem('lang') || 'ar';
    localStorage.clear();
    localStorage.setItem('lang', lang);
    if (!window.location.href.includes('/signup/')) this.router.navigateByUrl('/login')
  }
}
