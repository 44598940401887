import { Component } from '@angular/core';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'app-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrl: './bottom-navbar.component.scss',
})
export class BottomNavbarComponent {
  public isCustomer: boolean;

  constructor(userService: UserService) {
    this.isCustomer = userService.getUserInfo()?.role === 'customer';
  }
}
