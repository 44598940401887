import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import {
  ButtonModule,
  DatePickerModule,
  DropdownModule,
  InputModule,
  LoadingModule,
  NumberModule,
  RadioModule,
} from 'carbon-components-angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OtpFormComponent } from './components/otp-form/otp-form.component';
import { BottomNavbarComponent } from './components/bottom-navbar/bottom-navbar.component';
import { HeadingComponent } from './components/heading/heading.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ImgSliderComponent } from './components/img-slider/img-slider.component';
import { BurgerMenuComponent } from './components/burger-menu/burger-menu.component';
import { RequestCardComponent } from './components/request-card/request-card.component';
import { ResponseCardComponent } from './components/response-card/response-card.component';
import { EmptyHomePageComponent } from './components/empty-home-page/empty-home-page.component';
import { ProfileImgUploadComponent } from './components/profile-img-upload/profile-img-upload.component';
import { ProjectLogComponent } from './components/project-log/project-log.component';
import { MultiFileUploadComponent } from './components/multi-file-upload/multi-file-upload.component';
import { SingleFileUploadComponent } from './components/single-file-upload/single-file-upload.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    HeaderComponent,
    OtpFormComponent,
    BottomNavbarComponent,
    HeadingComponent,
    FileUploadComponent,
    BreadcrumbComponent,
    ImgSliderComponent,
    BurgerMenuComponent,
    RequestCardComponent,
    ResponseCardComponent,
    EmptyHomePageComponent,
    ProfileImgUploadComponent,
    ProjectLogComponent,
    MultiFileUploadComponent,
    SingleFileUploadComponent,
    NumbersOnlyDirective
  ],
  imports: [
    CommonModule,
    ButtonModule,
    InputModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DropdownModule,
    DatePickerModule,
    LoadingModule,
    NumberModule,
    InfiniteScrollDirective,
    RadioModule
  ],
  exports: [
    HeaderComponent,
    ButtonModule,
    InputModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    OtpFormComponent,
    BottomNavbarComponent,
    HeadingComponent,
    FileUploadComponent,
    BreadcrumbComponent,
    DatePickerModule,
    LoadingModule,
    NumberModule,
    ImgSliderComponent,
    BurgerMenuComponent,
    RequestCardComponent,
    ResponseCardComponent,
    EmptyHomePageComponent,
    ProfileImgUploadComponent,
    ProjectLogComponent,
    SingleFileUploadComponent,
    MultiFileUploadComponent,
    NumbersOnlyDirective,
    InfiniteScrollDirective,
    RadioModule
  ],
})
export class SharedModule {}
