import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[numbersOnly]'
})
export class NumbersOnlyDirective {

  constructor() { }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete'];
    const inputChar = String.fromCharCode(event.charCode);

    if (!/^\d+$/.test(inputChar) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData?.getData('text/plain');
    if (pastedData && !/^\d+$/.test(pastedData)) {
      event.preventDefault();
    }
  }
}
