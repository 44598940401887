import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user!: User;

  constructor(auth: AuthService, private http: HttpClient) {
    const userString: any = localStorage.getItem('userInfo'); // bypass the typing
    if (!userString) auth.logOut();
    else this.user = JSON.parse(userString);
  }

  public getUserInfo(): User {
    const userStr: any = localStorage.getItem('userInfo');
    return JSON.parse(userStr);
  }

  public getAccessToken(): string {
    const tokenStr = localStorage.getItem('token');
    if (tokenStr) return JSON.parse(tokenStr).access;
    return '';
  }

  public getUserProfile(): Observable<User> {
    const url = `${environment.apiUrl}/auth/me`;
    return this.http.get<User>(url);
  }

  public updateUserProfile(data: FormData): Observable<User> {
    const url = `${environment.apiUrl}/auth/user-info`;
    return this.http.patch<User>(url, data);
  }
}
