<a class="request-card" routerLink="/requests/{{ request.id }}">
  <div class="info">
    <div class="title txt-caption txt-caption-black fw-600">
      {{ request.service + " - " + request.district }}
    </div>
    
    <ng-container *ngIf="isCustomer">
      <div *ngIf="request.status" class="chip txt-caption chip-{{ getRequestStatusColor(request.status.code) }}">
        {{ request.status.name }}
      </div>
    </ng-container>

    <ng-container *ngIf="!isCustomer; else offersIndicator">
      <p class="txt txt-caption">{{'CLIENT' | translate}}: {{request.customer?.name}}</p>
      <div class="chip txt-caption chip-success">
        {{ request.duration }}
      </div>
    </ng-container>

    <ng-template #offersIndicator>
      <span *ngIf="request.response_count < 1" class="txt-caption fw-600 txt-xs offers-indicator">{{ 'OFFERS.NO-OFFERS-YET' | translate }}</span>
      <span *ngIf="request.response_count == 1" class="txt-caption fw-600 txt-xs offers-indicator offers-indicator-success">{{'OFFERS.YOU-HAVE-OFFER' | translate }}</span>
      <span *ngIf="request.response_count > 1" class="txt-caption fw-600 txt-xs offers-indicator offers-indicator-success">{{'OFFERS.YOU-HAVE-OFFERS' | translate:{number: request.response_count} }}</span>
    </ng-template>

  </div>
  <div class="images">
    <ng-container *ngFor="let img of request.images; let i = index">
      <img *ngIf="i < 4" [src]="img" />
    </ng-container>
    <ng-container *ngFor="let i of [4, 3, 2, 1]">
      <div *ngIf="i > request.images.length" class="img-placeholder"></div>
    </ng-container>
  </div>
</a>
