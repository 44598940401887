import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrl: './burger-menu.component.scss',
})
export class BurgerMenuComponent {
  public isOpen = false;
  public lang;

  constructor(private router: Router) {
    this.lang = localStorage.getItem('lang') || 'ar';
  }

  public switchLang(): void {
    this.isOpen = false;
    this.lang = this.lang == 'ar' ? 'en' : 'ar';
    localStorage.setItem('lang', this.lang);
    window.location.reload();
  }

  public logout(): void {
    this.isOpen = false;
    const lang = localStorage.getItem('lang');
    localStorage.clear();
    if (lang) localStorage.setItem('lang', lang);
    this.router.navigateByUrl('/login');
  }

  get isLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }
}
