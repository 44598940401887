import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserFile } from '../../../core/models/user.model';
import { FilesService } from '../../../core/services/files.service';

@Component({
  selector: 'app-single-file-upload',
  templateUrl: './single-file-upload.component.html',
  styleUrl: './single-file-upload.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleFileUploadComponent),
      multi: true,
    },
  ],
})
export class SingleFileUploadComponent implements ControlValueAccessor {
  @Input() disabled?: boolean;
  @Input() loading?: boolean;
  @Input() endpoint!: string;
  @Input() kind!: UserFile;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() invalid?: boolean;
  @Input() invalidTxt?: string;
  @Input() hideCaption?: boolean;

  @Output() onSelect = new EventEmitter<File[]>();
  @Output() onUpload = new EventEmitter<{ id: number }>();

  private static id = 0;
  private touched = false;
  public inputId: string;
  public files: File[] = [];
  public uploadedFile!: { file: File; id: number };
  // control value accessor methods
  public onChange = (value: any) => {};
  public onTouched = () => {};

  constructor(private filesService: FilesService) {
    this.inputId = 'single_upload_input' + SingleFileUploadComponent.id++;
  }

  public onFileSelected(event: any /* input file change event */): void {
    if (Object.keys(event.target.files).length) this.files = [];
    const selectedFiles: FileList = event.target.files;
    const file = selectedFiles.item(0) as File;
    if (this.filesService.isValidFile(file)) this.files.push(file);

    this.onSelect.emit(this.files);

    if (this.endpoint && this.files.length) this.uploadFile(this.files[0]);
    else this.onChange(this.files);
  }

  private uploadFile(file: File): void {
    this.startLoading();
    if (this.isSameFile(file)) this.stopLoading();
    else {
      const data = new FormData();
      data.append('file', file, file?.name);
      if (this.kind) data.append('kind', this.kind);
      this.filesService.uploadFile(data, this.endpoint).subscribe({
        next: (res: { id: number }) => {
          this.uploadedFile = { file, id: res.id };
          this.markAsTouched();
          this.onUpload.emit(res);
          this.stopLoading();
        },
        error: () => {
          // handle error error handle
        },
      });
    }
  }

  /* ======= */
  /* Helpers */
  /* ======= */
  private startLoading(): void {
    this.loading = true;
    this.disabled = true;
  }

  private stopLoading(): void {
    this.loading = false;
    this.disabled = false;
  }

  private isSameFile(file: File): boolean {
    if (!this.uploadedFile) return false;
    return (
      file.lastModified === this.uploadedFile.file.lastModified &&
      file.name === this.uploadedFile.file.name &&
      file.size === this.uploadedFile.file.size
    );
  }

  /* ============================== */
  /* Control Value Accessor Methods */
  /* ============================== */
  writeValue(value: any): void {
    // Not needed for file upload
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Not needed for file upload
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
