import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent {
  @Input() title!: string;
  @Input() caption?: string;
  @Input() backTo!: string;

  public rtl: boolean;

  constructor() {
    this.rtl = localStorage.getItem('lang') === 'ar'
  }

  public goBack(): void {
    if (!this.backTo) history.back()
  }
}
