import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { UserFile } from '../../../core/models/user.model';
import { FileObject } from '../../../core/models/global.model';
import { FilesService } from '../../../core/services/files.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-multi-file-upload',
  templateUrl: './multi-file-upload.component.html',
  styleUrl: './multi-file-upload.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiFileUploadComponent),
      multi: true,
    },
  ],
})
export class MultiFileUploadComponent implements ControlValueAccessor {
  @Input() disabled?: boolean;
  @Input() loading?: boolean;
  @Input() endpoint!: string;
  @Input() label?: string;
  @Input() kind?: UserFile;
  @Input() placeholder?: string;
  @Input() buttonLabel = 'UPLOAD-FILES';
  @Input() invalid?: boolean;
  @Input() invalidTxt?: string;
  @Input() hideCaption?: boolean;
  @Input() ghostFiles: FileObject[] = [];
  @Input() ghostDelete?: boolean;

  @Output() onSelect = new EventEmitter<File[]>();
  @Output() onMultiUpload = new EventEmitter<number[]>();
  @Output() onDelete = new EventEmitter<number>(); // returns the Id of the deleted file

  private static id = 0;
  private touched = false;
  public inputId: string;
  public files: File[] = [];
  public uploadedFiles: { file: File; id: number }[] = [];
  // control value accessor methods
  public onChange = (value: any) => {};
  public onTouched = () => {};

  constructor(private filesService: FilesService) {
    this.inputId = 'upload_input' + MultiFileUploadComponent.id++;
  }

  public onFilesSelect(event: any): void {
    const selectedFiles: FileList = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles.item(i) as File;
      if (this.filesService.isValidFile(file) && !this.isExisted(file)) {
        this.files.push(file);
      }
    }

    this.onSelect.emit(this.files);
    if (this.endpoint) this.uploadFiles(this.files);
    else this.onChange(this.files);
  }

  private uploadFiles(files: File[]): void {
    const ids: number[] = [];
    let i = 0;
    files.forEach((file) => {
      // if (!this.isExisted(file)) {
        this.startLoading();
        const data = new FormData();
        data.append('file', file, file?.name);
        if (this.kind) data.append('kind', this.kind);
        this.filesService.uploadFile(data, this.endpoint).subscribe({
          next: (res: { id: number }) => {
            this.uploadedFiles.push({ file, id: res.id });
            ids.push(res.id);
            i++;
            if (i === files.length) {
              this.markAsTouched();
              this.onMultiUpload.emit(this.allFilesIds);
              this.stopLoading();
            }
          },
          error: () => {
            // handle error error handle
          },
        });
      // } else this.stopLoading();
    });
  }

  public deleteFile(file: File): void {
    const i = this.uploadedFiles.findIndex(
      (f) =>
        f.file.lastModified === file.lastModified &&
        f.file.name === file.name &&
        f.file.size === file.size
    );
    if (i >= 0) this.uploadedFiles.splice(i, 1);
  }

  public ghostDeleteFile(file: FileObject): void {}

  /* ======= */
  /* Helpers */
  /* ======= */
  private startLoading(): void {
    this.loading = true;
    this.disabled = true;
  }

  private stopLoading(): void {
    this.loading = false;
    this.disabled = false;
  }

  private isExisted(file: File): boolean {
    if (!this.files.length) return false;
    return !!this.files.find(
      (f) =>
        f.lastModified === file.lastModified &&
        f.name === file.name &&
        f.size === file.size
    );
  }

  private get allFilesIds(): number[] {
    const files = [
      ...this.uploadedFiles.map((f) => f.id),
      ...this.ghostFiles?.map((f) => f.id),
    ];
    const filesWithoutDuplicates: number[] = Array.from(new Set(files));
    return filesWithoutDuplicates;
  }

  /* ============================== */
  /* Control Value Accessor Methods */
  /* ============================== */
  public writeValue(value: any): void {
    // Not needed for file upload
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    // Not needed for file upload
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
