import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-img-slider',
  templateUrl: './img-slider.component.html',
  styleUrl: './img-slider.component.scss',
})
export class ImgSliderComponent {
  @Input() images!: string[];
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() nonClickable?: boolean;
}
