import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrl: './heading.component.scss'
})
export class HeadingComponent {
  @Input() title!: string;
  @Input() caption?: string;
  @Input() size: 'md' | 'lg' = 'lg';
}
