<a class="request-card" [routerLink]="'/offers/' + offer.id">
  <div class="info flx-1">
    <p
      class="title offer-title txt-caption txt-caption-black fw-600 flx flx-c flx-sb"
    >
      {{ offer.service + " - " + offer.district }}
      <i class="fi-chevron-left reverse"></i>
    </p>
    <p class="txt-caption fw-500">
      {{ "CLIENT" | translate }}: {{ offer.customer.name }}
    </p>
    <div class="chip txt-caption chip-{{ getOfferStatusColor(offer.status) }}">
      {{ "OFFER-STATUS." + offer.status | translate }}
    </div>
  </div>
</a>
