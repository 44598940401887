import { Component, Input } from '@angular/core';
import { OfferStatus, Offer } from '../../../core/models/offers.model';
import { StatusColor } from '../../../core/models/global.model';

@Component({
  selector: 'app-response-card',
  templateUrl: './response-card.component.html',
  styleUrl: './response-card.component.scss',
})
export class ResponseCardComponent {
  @Input() offer!: Offer;

  public getOfferStatusColor(status: OfferStatus): StatusColor {
    return status == 'accepted'
      ? 'success'
      : status == 'rejected'
      ? 'danger'
      : status == 'complete'
      ? 'info'
      : 'neutral';
  }
}
