import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OfferStep, ProjectStep } from '../../../core/models/offers.model';
import { OffersService } from '../../../pages/offers/services/offers.service';

@Component({
  selector: 'app-project-log',
  templateUrl: './project-log.component.html',
  styleUrl: './project-log.component.scss',
})
export class ProjectLogComponent {
  public loading = false;

  @Input() offerId!: number;
  @Input() projectSteps!: ProjectStep[];
  @Input() offerSteps!: OfferStep[];

  @Output() onAction = new EventEmitter();

  constructor(private offersService: OffersService) {}

  public performAction(step: ProjectStep, index: number): void {
    if (!this.isActiveStep(index)) return;
    this.loading = true;
    const data = {
      action: step?.next_action?.code as string,
      step_id: step.step_id,
    };
    this.offersService.changeStatus(this.offerId, data).subscribe({
      next: () => {
        this.onAction.emit();
        this.loading = false;
      },
      error: () => (this.loading = false),
    });
  }

  public getProgressPercentage(): number {
    const currStepIdx = this.projectSteps.findIndex((s) => s.is_current_step);
    return ((currStepIdx + 1) / this.projectSteps.length) * 100;
  }

  public isActiveStep(index: number): boolean {
    return this.projectSteps.length == (index + 1);
  }
}
