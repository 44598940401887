<label id="inp_label" for="profile-pic-uploader">
  <img [src]="imgUrl" width="100%" height="100%" />
  <i *ngIf="!imgUrl" class="fi-profile img-placeholder"></i>
  <div class="loading-container" *ngIf="loading">
    <cds-loading
      [isActive]="true"
      size="sm"
      class="cds-loading-disabled"
    ></cds-loading>
  </div>
  <i class="fi-camera upload-img-ico"></i>
  <input
    type="file"
    id="profile-pic-uploader"
    accept=".png,.jpg,.jpeg"
    (change)="onImgUpload($event)"
  />
</label>
