<label
  *ngIf="label"
  [for]="inputId"
  class="txt-caption txt-caption-black label--multi"
  >{{ label | translate }}</label
>
<p *ngIf="!hideCaption" class="txt-caption caption--multi">
  {{ "UPLOAD-INPUT-CAPTION" | translate }}
</p>
<label class="upload-rounded-btn" [for]="inputId">
  <button cdsButton size="sm" class="btn-rounded">
    {{ buttonLabel | translate }}<i class="fi-plus-circle"></i>
  </button>
  <input
    [id]="inputId"
    type="file"
    multiple
    accept=".png,.jpg,.jpeg,.pdf"
    (change)="onFilesSelect($event)"
  />
</label>
<p *ngIf="loading" class="txt-caption">loading...</p>
<div
  *ngFor="let file of uploadedFiles; let i = index"
  class="file-preview"
  [ngClass]="{ disabled, 'fade-in-out': loading }"
>
  <p class="truncate">{{ file.file.name }}</p>
  <i class="fi-trash" (click)="deleteFile(file.file)"></i>
</div>
<!-- current file list incase of editing a form -->
<div
  *ngFor="let file of ghostFiles"
  class="file-preview"
  [ngClass]="{ disabled, 'fade-in-out': loading }"
>
  <p class="truncate">{{ file.file_name }}</p>
  <i class="fi-trash" (click)="ghostDeleteFile(file)"></i>
</div>
