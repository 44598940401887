import * as i0 from '@angular/core';
import { EventEmitter, TemplateRef, Component, Input, Output, ViewChild, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as i2 from 'carbon-components-angular/icon';
import { IconModule } from 'carbon-components-angular/icon';
const _c0 = ["input"];
function DatePickerInput_label_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r8.label);
  }
}
function DatePickerInput_label_3_2_ng_template_0_Template(rf, ctx) {}
function DatePickerInput_label_3_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, DatePickerInput_label_3_2_ng_template_0_Template, 0, 0, "ng-template", 13);
  }
  if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r9.label);
  }
}
const _c1 = a0 => ({
  "cds--label--disabled": a0
});
function DatePickerInput_label_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 11);
    i0.ɵɵtemplate(1, DatePickerInput_label_3_ng_container_1_Template, 2, 1, "ng-container", 12)(2, DatePickerInput_label_3_2_Template, 1, 1, null, 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("for", ctx_r0.id)("ngClass", i0.ɵɵpureFunction1(4, _c1, ctx_r0.disabled));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.isTemplate(ctx_r0.label));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.isTemplate(ctx_r0.label));
  }
}
const _c2 = (a0, a1, a2) => ({
  "cds--date-picker__input--sm": a0,
  "cds--date-picker__input--md": a1,
  "cds--date-picker__input--lg": a2
});
function DatePickerInput_input_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r13 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "input", 14, 15);
    i0.ɵɵlistener("change", function DatePickerInput_input_5_Template_input_change_0_listener($event) {
      i0.ɵɵrestoreView(_r13);
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.onChange($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(7, _c2, ctx_r1.size === "sm", ctx_r1.size === "md", ctx_r1.size === "lg"))("value", ctx_r1.value)("pattern", ctx_r1.pattern)("placeholder", ctx_r1.placeholder)("id", ctx_r1.id)("disabled", ctx_r1.disabled);
    i0.ɵɵattribute("data-invalid", ctx_r1.invalid ? true : undefined);
  }
}
function DatePickerInput__svg_svg_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 16);
  }
}
function DatePickerInput__svg_svg_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 17);
  }
}
function DatePickerInput__svg_svg_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 18);
  }
}
function DatePickerInput_div_9_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r14 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r14.helperText);
  }
}
function DatePickerInput_div_9_2_ng_template_0_Template(rf, ctx) {}
function DatePickerInput_div_9_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, DatePickerInput_div_9_2_ng_template_0_Template, 0, 0, "ng-template", 13);
  }
  if (rf & 2) {
    const ctx_r15 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r15.helperText);
  }
}
const _c3 = a0 => ({
  "cds--form__helper-text--disabled": a0
});
function DatePickerInput_div_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 19);
    i0.ɵɵtemplate(1, DatePickerInput_div_9_ng_container_1_Template, 2, 1, "ng-container", 12)(2, DatePickerInput_div_9_2_Template, 1, 1, null, 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c3, ctx_r5.disabled));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r5.isTemplate(ctx_r5.helperText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r5.isTemplate(ctx_r5.helperText));
  }
}
function DatePickerInput_div_10_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r17 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r17.invalidText);
  }
}
function DatePickerInput_div_10_2_ng_template_0_Template(rf, ctx) {}
function DatePickerInput_div_10_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, DatePickerInput_div_10_2_ng_template_0_Template, 0, 0, "ng-template", 13);
  }
  if (rf & 2) {
    const ctx_r18 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r18.invalidText);
  }
}
function DatePickerInput_div_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 20);
    i0.ɵɵtemplate(1, DatePickerInput_div_10_ng_container_1_Template, 2, 1, "ng-container", 12)(2, DatePickerInput_div_10_2_Template, 1, 1, null, 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r6.isTemplate(ctx_r6.invalidText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r6.isTemplate(ctx_r6.invalidText));
  }
}
function DatePickerInput_div_11_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r20 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r20.warnText);
  }
}
function DatePickerInput_div_11_2_ng_template_0_Template(rf, ctx) {}
function DatePickerInput_div_11_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, DatePickerInput_div_11_2_ng_template_0_Template, 0, 0, "ng-template", 13);
  }
  if (rf & 2) {
    const ctx_r21 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r21.warnText);
  }
}
function DatePickerInput_div_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 20);
    i0.ɵɵtemplate(1, DatePickerInput_div_11_ng_container_1_Template, 2, 1, "ng-container", 12)(2, DatePickerInput_div_11_2_Template, 1, 1, null, 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r7.isTemplate(ctx_r7.warnText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r7.isTemplate(ctx_r7.warnText));
  }
}
const _c4 = (a0, a1, a2, a3, a4) => ({
  "cds--date-picker--simple": a0,
  "cds--date-picker--single": a1,
  "cds--date-picker--range": a2,
  "cds--date-picker--light": a3,
  "cds--skeleton": a4
});
const _c5 = (a0, a1) => ({
  "cds--date-picker-input__wrapper--invalid": a0,
  "cds--date-picker-input__wrapper--warn": a1
});
class DatePickerInput {
  constructor(elementRef) {
    this.elementRef = elementRef;
    /**
     * Select a calendar type for the `model`.
     * Internal purposes only.
     */
    this.type = "simple";
    this.id = `datepicker-${DatePickerInput.datePickerCount++}`;
    this.hasIcon = false;
    this.placeholder = "mm/dd/yyyy";
    this.pattern = "^\\d{1,2}/\\d{1,2}/\\d{4}$";
    this.valueChange = new EventEmitter();
    /**
     * @deprecated since v5 - Use `cdsLayer` directive instead
     * Set to `"light"` to apply the light style
     */
    this.theme = "dark";
    this.disabled = false;
    /**
     * Set to `true` for invalid state.
     */
    this.invalid = false;
    /**
      * Set to `true` to show a warning (contents set by warnText)
      */
    this.warn = false;
    this.skeleton = false;
    this.value = "";
    this.size = "md";
    this.onTouched = () => {};
    this.propagateChange = _ => {};
  }
  onChange(event) {
    this.value = event.target.value;
    this.valueChange.emit(this.value);
    this.propagateChange(this.value);
    this.onTouched();
  }
  writeValue(value) {
    this.value = value;
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  isTemplate(value) {
    return value instanceof TemplateRef;
  }
}
DatePickerInput.datePickerCount = 0;
DatePickerInput.ɵfac = function DatePickerInput_Factory(t) {
  return new (t || DatePickerInput)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
DatePickerInput.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DatePickerInput,
  selectors: [["cds-date-picker-input"], ["ibm-date-picker-input"]],
  viewQuery: function DatePickerInput_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.input = _t.first);
    }
  },
  inputs: {
    type: "type",
    id: "id",
    hasIcon: "hasIcon",
    label: "label",
    placeholder: "placeholder",
    pattern: "pattern",
    theme: "theme",
    disabled: "disabled",
    invalid: "invalid",
    invalidText: "invalidText",
    warn: "warn",
    warnText: "warnText",
    helperText: "helperText",
    skeleton: "skeleton",
    value: "value",
    size: "size"
  },
  outputs: {
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DatePickerInput,
    multi: true
  }])],
  decls: 12,
  vars: 19,
  consts: [[1, "cds--form-item"], [1, "cds--date-picker", 3, "ngClass"], [1, "cds--date-picker-container"], ["class", "cds--label", 3, "for", "ngClass", 4, "ngIf"], [1, "cds--date-picker-input__wrapper", 3, "ngClass"], ["autocomplete", "off", "type", "text", "class", "cds--date-picker__input", 3, "ngClass", "value", "pattern", "placeholder", "id", "disabled", "change", 4, "ngIf"], ["cdsIcon", "calendar", "size", "16", "class", "cds--date-picker__icon", 4, "ngIf"], ["class", "cds--date-picker__icon cds--date-picker__icon--invalid", "cdsIcon", "warning--filled", "size", "16", 4, "ngIf"], ["cdsIcon", "warning--alt--filled", "size", "16", "class", "cds--date-picker__icon cds--date-picker__icon--warn", 4, "ngIf"], ["class", "cds--form__helper-text", 3, "ngClass", 4, "ngIf"], ["class", "cds--form-requirement", 4, "ngIf"], [1, "cds--label", 3, "for", "ngClass"], [4, "ngIf"], [3, "ngTemplateOutlet"], ["autocomplete", "off", "type", "text", 1, "cds--date-picker__input", 3, "ngClass", "value", "pattern", "placeholder", "id", "disabled", "change"], ["input", ""], ["cdsIcon", "calendar", "size", "16", 1, "cds--date-picker__icon"], ["cdsIcon", "warning--filled", "size", "16", 1, "cds--date-picker__icon", "cds--date-picker__icon--invalid"], ["cdsIcon", "warning--alt--filled", "size", "16", 1, "cds--date-picker__icon", "cds--date-picker__icon--warn"], [1, "cds--form__helper-text", 3, "ngClass"], [1, "cds--form-requirement"]],
  template: function DatePickerInput_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2);
      i0.ɵɵtemplate(3, DatePickerInput_label_3_Template, 3, 6, "label", 3);
      i0.ɵɵelementStart(4, "div", 4);
      i0.ɵɵtemplate(5, DatePickerInput_input_5_Template, 2, 11, "input", 5)(6, DatePickerInput__svg_svg_6_Template, 1, 0, "svg", 6)(7, DatePickerInput__svg_svg_7_Template, 1, 0, "svg", 7)(8, DatePickerInput__svg_svg_8_Template, 1, 0, "svg", 8);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(9, DatePickerInput_div_9_Template, 3, 5, "div", 9)(10, DatePickerInput_div_10_Template, 3, 2, "div", 10)(11, DatePickerInput_div_11_Template, 3, 2, "div", 10);
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction5(10, _c4, ctx.type === "simple", ctx.type === "single", ctx.type === "range", ctx.theme === "light", ctx.skeleton));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.label);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(16, _c5, ctx.invalid, ctx.warn));
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.skeleton);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.type !== "simple" && !ctx.warn && !ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.warn && ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.invalid && ctx.warn);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.helperText && !ctx.invalid && !ctx.warn);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.warn && ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.invalid && ctx.warn);
    }
  },
  dependencies: [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i2.IconDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DatePickerInput, [{
    type: Component,
    args: [{
      selector: "cds-date-picker-input, ibm-date-picker-input",
      template: `
	<div class="cds--form-item">
		<div class="cds--date-picker"
			[ngClass]="{
				'cds--date-picker--simple' : type === 'simple',
				'cds--date-picker--single' : type === 'single',
				'cds--date-picker--range' : type === 'range',
				'cds--date-picker--light' : theme === 'light',
				'cds--skeleton' : skeleton
			}">
			<div class="cds--date-picker-container">
				<label
					*ngIf="label"
					[for]="id"
					class="cds--label"
					[ngClass]="{'cds--label--disabled': disabled}">
					<ng-container *ngIf="!isTemplate(label)">{{label}}</ng-container>
					<ng-template *ngIf="isTemplate(label)" [ngTemplateOutlet]="label"></ng-template>
				</label>
				<div class="cds--date-picker-input__wrapper"
					[ngClass]="{
						'cds--date-picker-input__wrapper--invalid': invalid,
						'cds--date-picker-input__wrapper--warn': warn
					}">
					<input
						#input
						*ngIf="!skeleton"
						autocomplete="off"
						type="text"
						class="cds--date-picker__input"
						[ngClass]="{
							'cds--date-picker__input--sm': size === 'sm',
							'cds--date-picker__input--md': size === 'md',
							'cds--date-picker__input--lg': size === 'lg'
						}"
						[attr.data-invalid]="invalid ? true : undefined"
						[value]="value"
						[pattern]="pattern"
						[placeholder]="placeholder"
						[id]= "id"
						[disabled]="disabled"
						(change)="onChange($event)"/>
						<svg
							*ngIf="type !== 'simple' && !warn && !invalid"
							cdsIcon="calendar"
							size="16"
							class="cds--date-picker__icon">
						</svg>
						<svg
							*ngIf="!warn && invalid"
							class="cds--date-picker__icon cds--date-picker__icon--invalid"
							cdsIcon="warning--filled"
							size="16">
						</svg>
						<svg
							*ngIf="!invalid && warn"
							cdsIcon="warning--alt--filled"
							size="16"
							class="cds--date-picker__icon cds--date-picker__icon--warn">
						</svg>
				</div>
				<div
					*ngIf="helperText && !invalid && !warn"
					class="cds--form__helper-text"
					[ngClass]="{'cds--form__helper-text--disabled': disabled}">
					<ng-container *ngIf="!isTemplate(helperText)">{{helperText}}</ng-container>
					<ng-template *ngIf="isTemplate(helperText)" [ngTemplateOutlet]="helperText"></ng-template>
				</div>
				<div *ngIf="!warn && invalid" class="cds--form-requirement">
					<ng-container *ngIf="!isTemplate(invalidText)">{{invalidText}}</ng-container>
					<ng-template *ngIf="isTemplate(invalidText)" [ngTemplateOutlet]="invalidText"></ng-template>
				</div>
				<div *ngIf="!invalid && warn" class="cds--form-requirement">
					<ng-container *ngIf="!isTemplate(warnText)">{{warnText}}</ng-container>
					<ng-template *ngIf="isTemplate(warnText)" [ngTemplateOutlet]="warnText"></ng-template>
				</div>
			</div>
		</div>
</div>
	`,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: DatePickerInput,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    type: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    hasIcon: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    pattern: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }],
    theme: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    invalid: [{
      type: Input
    }],
    invalidText: [{
      type: Input
    }],
    warn: [{
      type: Input
    }],
    warnText: [{
      type: Input
    }],
    helperText: [{
      type: Input
    }],
    skeleton: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    input: [{
      type: ViewChild,
      args: ["input"]
    }]
  });
})();
class DatePickerInputModule {}
DatePickerInputModule.ɵfac = function DatePickerInputModule_Factory(t) {
  return new (t || DatePickerInputModule)();
};
DatePickerInputModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DatePickerInputModule
});
DatePickerInputModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, IconModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DatePickerInputModule, [{
    type: NgModule,
    args: [{
      declarations: [DatePickerInput],
      exports: [DatePickerInput],
      imports: [CommonModule, IconModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DatePickerInput, DatePickerInputModule };
