import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilesService } from '../../../core/services/files.service';
@Component({
  selector: 'app-profile-img-upload',
  templateUrl: './profile-img-upload.component.html',
  styleUrl: './profile-img-upload.component.scss',
})
export class ProfileImgUploadComponent {
  @Input() imgUrl!: string;
  @Output() onUpload = new EventEmitter<File>();

  public loading = false;

  constructor() {}

  public onImgUpload(evt: Event): void {
    const inputEl = evt.target as HTMLInputElement;
    const files = inputEl.files as FileList;
    if (files && files.length) {
      this.loading = true;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (evt: ProgressEvent<FileReader>) => {
        this.imgUrl = evt.target?.result as string;
        this.loading = false;
      };
      this.onUpload.emit(files[0]);
    }
  }
}
