import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  public lang;

  constructor(private router: Router) {
    this.lang = localStorage.getItem('lang') || 'ar';
  }

  public switchLang(): void {
    this.lang = this.lang == 'ar' ? 'en' : 'ar';
    localStorage.setItem('lang', this.lang);
    window.location.reload()
  }

  public logout(): void {
    const lang = localStorage.getItem('lang')
    localStorage.clear()
    if (lang) localStorage.setItem('lang', lang)
    this.router.navigateByUrl('/login')
  }

  get isLoggedIn(): boolean {
    return !!localStorage.getItem('token')
  }

  get hideProviderRegistrationSuggestion(): boolean {
    return this.router.url.includes('signup/provider')
  }
}
