import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewEncapsulation, Input, ViewChild, Output, HostListener, NgModule } from '@angular/core';
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import flatpickr from 'flatpickr';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as languages from 'flatpickr/dist/l10n/index';
import * as i1 from 'carbon-components-angular/i18n';
import { I18nModule } from 'carbon-components-angular/i18n';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from 'carbon-components-angular/datepicker-input';
import { DatePickerInputModule } from 'carbon-components-angular/datepicker-input';
import { UtilsModule } from 'carbon-components-angular/utils';

/**
 * This is from carbon-components.
 * We need it to format the month select according to specs.
 * Carbon currently doesn't expose this as a seperate package,
 * and we don't import the carbon-components js (on purpose)
 * so some copy pasta is required
 *
 * ref: https://github.com/carbon-design-system/carbon/blob/
 * 	f06f38f0c2ef624e409a3d5711e897a79f4c88fc/packages/components/src/components/date-picker/date-picker.js#L52-L123
 */
const _c0 = ["input"];
const _c1 = ["rangeInput"];
function DatePicker_div_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2)(1, "cds-date-picker-input", 3, 6);
    i0.ɵɵlistener("valueChange", function DatePicker_div_5_Template_cds_date_picker_input_valueChange_1_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.onRangeValueChange($event));
    })("click", function DatePicker_div_5_Template_cds_date_picker_input_click_1_listener() {
      i0.ɵɵrestoreView(_r4);
      const _r2 = i0.ɵɵreference(2);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.openCalendar(_r2));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("label", ctx_r1.rangeLabel)("placeholder", ctx_r1.placeholder)("pattern", ctx_r1.inputPattern)("id", ctx_r1.id + "-rangeInput")("size", ctx_r1.size)("type", ctx_r1.range ? "range" : "single")("hasIcon", ctx_r1.range ? true : null)("disabled", ctx_r1.disabled)("invalid", ctx_r1.rangeInvalid)("invalidText", ctx_r1.rangeInvalidText)("warn", ctx_r1.rangeWarn)("warnText", ctx_r1.rangeWarnText)("skeleton", ctx_r1.skeleton)("helperText", ctx_r1.rangeHelperText);
  }
}
const _c2 = (a0, a1, a2, a3) => ({
  "cds--date-picker--range": a0,
  "cds--date-picker--single": a1,
  "cds--date-picker--light": a2,
  "cds--skeleton": a3
});
const monthToStr = (monthNumber, shorthand, locale) => locale.months[shorthand ? "shorthand" : "longhand"][monthNumber];
const config = {
  selectorInit: "[data-date-picker]",
  selectorDatePickerInput: "[data-date-picker-input]",
  selectorDatePickerInputFrom: "[data-date-picker-input-from]",
  selectorDatePickerInputTo: "[data-date-picker-input-to]",
  selectorDatePickerIcon: "[data-date-picker-icon]",
  selectorFlatpickrMonthYearContainer: ".flatpickr-current-month",
  selectorFlatpickrYearContainer: ".numInputWrapper",
  selectorFlatpickrCurrentMonth: ".cur-month",
  classCalendarContainer: `cds--date-picker__calendar`,
  classMonth: `cds--date-picker__month`,
  classWeekdays: `cds--date-picker__weekdays`,
  classDays: `cds--date-picker__days`,
  classWeekday: `cds--date-picker__weekday`,
  classDay: `cds--date-picker__day`,
  classFocused: `cds--focused`,
  classVisuallyHidden: `cds--visually-hidden`,
  classFlatpickrCurrentMonth: "cur-month",
  attribType: "data-date-picker-type",
  dateFormat: "m/d/Y",
  shorthand: false
};
const carbonFlatpickrMonthSelectPlugin = fp => {
  const setupElements = () => {
    if (!fp.monthElements || !fp.yearElements) {
      return;
    }
    fp.monthElements.forEach(elem => {
      if (!elem.parentNode) {
        return;
      }
      elem.parentNode.removeChild(elem);
    });
    fp.monthElements.splice(0, fp.monthElements.length, ...fp.monthElements.map(() => {
      // eslint-disable-next-line no-underscore-dangle
      const monthElement = fp._createElement("span", config.classFlatpickrCurrentMonth);
      monthElement.textContent = monthToStr(fp.currentMonth, config.shorthand === true, fp.l10n);
      fp.yearElements[0].closest(config.selectorFlatpickrMonthYearContainer).insertBefore(monthElement, fp.yearElements[0].closest(config.selectorFlatpickrYearContainer));
      return monthElement;
    }));
  };
  const updateCurrentMonth = () => {
    if (!fp.yearElements) {
      return;
    }
    const monthStr = monthToStr(fp.currentMonth, config.shorthand === true, fp.l10n);
    fp.yearElements.forEach(elem => {
      const currentMonthContainer = elem.closest(config.selectorFlatpickrMonthYearContainer);
      Array.prototype.forEach.call(currentMonthContainer.querySelectorAll(".cur-month"), monthElement => {
        monthElement.textContent = monthStr;
      });
    });
  };
  const register = () => {
    fp.loadedPlugins.push("carbonFlatpickrMonthSelectPlugin");
  };
  return {
    onMonthChange: updateCurrentMonth,
    onValueUpdate: updateCurrentMonth,
    onOpen: updateCurrentMonth,
    onReady: [setupElements, updateCurrentMonth, register]
  };
};

/**
 * Due to type error, we have to use square brackets property accessor
 * There is a webpack issue when attempting to access exported languages from flatpickr l10n Angular 14+ apps
 * languages.default[locale] fails in app consuming CCA library but passes in test
 * languages.default.default[locale] fails in test but works in app consuming CCA library.
 *
 * To please both scenarios, we are adding a condition to prevent tests from failing
 */
if (languages.default?.default["en"]?.weekdays) {
  languages.default.default["en"].weekdays.shorthand = languages.default.default["en"].weekdays.longhand.map(day => {
    if (day === "Thursday") {
      return "Th";
    }
    return day.charAt(0);
  });
}
/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { DatePickerModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-date-picker--single)
 */
class DatePicker {
  constructor(elementRef, i18n) {
    this.elementRef = elementRef;
    this.i18n = i18n;
    /**
     * Select calendar range mode
     */
    this.range = false;
    /**
     * Format of date
     *
     * For reference: https://flatpickr.js.org/formatting/
     */
    this.dateFormat = "m/d/Y";
    /**
     * Language of the flatpickr calendar.
     *
     * For reference of the possible locales:
     * https://github.com/flatpickr/flatpickr/blob/master/src/l10n/index.ts
     */
    this.language = "en";
    this.placeholder = "mm/dd/yyyy";
    /**
     * Aria label added to datepicker's calendar container.
     */
    this.ariaLabel = "calendar container";
    /**
     * The pattern for the underlying input element
     */
    this.inputPattern = "^\\d{1,2}/\\d{1,2}/\\d{4}$";
    this.id = `datepicker-${DatePicker.datePickerCount++}`;
    /**
     * @deprecated since v5 - Use `cdsLayer` directive instead
     * Set to `"light"` to apply the light style
     */
    this.theme = "dark";
    this.disabled = false;
    /**
     * Set to `true` to display the invalid state.
     */
    this.invalid = false;
    /**
      * Set to `true` to show a warning (contents set by warningText)
      */
    this.warn = false;
    this.size = "md";
    /**
     * Set to `true` to display the invalid state for the second datepicker input.
     */
    this.rangeInvalid = false;
    /**
      * Set to `true` to show a warning in the second datepicker input (contents set by rangeWarningText)
      */
    this.rangeWarn = false;
    this.skeleton = false;
    this.plugins = [];
    this.valueChange = new EventEmitter();
    /**
     * We are overriding onClose event even if users pass it via flatpickr options
     * Emits an event when date picker closes
     */
    this.onClose = new EventEmitter();
    this._value = [];
    this._flatpickrOptions = {
      allowInput: true
    };
    this.flatpickrBaseOptions = {
      mode: "single",
      dateFormat: "m/d/Y",
      plugins: this.plugins,
      onOpen: () => {
        this.updateClassNames();
        this.updateAttributes();
        this.updateCalendarListeners();
      },
      onClose: date => {
        // This makes sure that the `flatpickrInstance selectedDates` are in sync with the values of
        // the inputs when the calendar closes.
        if (this.range && this.flatpickrInstance) {
          if (this.flatpickrInstance.selectedDates.length !== 2) {
            // we could `this.flatpickrInstance.clear()` but it insists on opening the second picker
            // in some cases, so instead we do this
            this.setDateValues([]);
            this.doSelect([]);
            return;
          }
          const inputValue = this.input.input.nativeElement.value;
          const rangeInputValue = this.rangeInput.input.nativeElement.value;
          if (inputValue || rangeInputValue) {
            const parseDate = date => this.flatpickrInstance.parseDate(date, this.dateFormat);
            this.setDateValues([parseDate(inputValue), parseDate(rangeInputValue)]);
            this.doSelect(this.flatpickrInstance.selectedDates);
          }
        }
        this.onClose.emit(date);
      },
      onDayCreate: (_dObj, _dStr, _fp, dayElem) => {
        dayElem.classList.add("cds--date-picker__day");
      },
      nextArrow: this.rightArrowHTML(),
      prevArrow: this.leftArrowHTML(),
      value: this.value
    };
    this.flatpickrInstance = null;
    this.onTouched = () => {};
    this.propagateChange = _ => {};
    this.preventCalendarClose = event => event.stopPropagation();
  }
  set value(v) {
    if (!v) {
      v = [];
    }
    this._value = v;
  }
  get value() {
    return this._value;
  }
  set flatpickrOptions(options) {
    this._flatpickrOptions = Object.assign({}, this._flatpickrOptions, options);
  }
  get flatpickrOptions() {
    const plugins = [...this.plugins, carbonFlatpickrMonthSelectPlugin];
    if (this.range) {
      plugins.push(rangePlugin({
        input: `#${this.id}-rangeInput`,
        position: "left"
      }));
    }
    return Object.assign({}, this._flatpickrOptions, this.flatpickrBaseOptions, {
      mode: this.range ? "range" : "single",
      plugins,
      dateFormat: this.dateFormat,
      locale: languages.default?.default[this.language] || languages.default[this.language]
    });
  }
  ngOnInit() {
    // if i18n is set to anything other than en we'll want to change the language
    // otherwise we'll just use the local setting
    if (this.i18n.getLocale() !== "en") {
      this.i18n.getLocaleObservable().subscribe(locale => {
        this.language = locale;
        this.resetFlatpickrInstance();
      });
    }
  }
  ngOnChanges(changes) {
    // Reset the flatpickr instance on input changes that affect flatpickr.
    const flatpickrChangeKeys = ["range", "dateFormat", "language", "id", "value", "plugins", "flatpickrOptions"];
    const changeKeys = Object.keys(changes);
    if (changeKeys.some(key => flatpickrChangeKeys.includes(key))) {
      this.resetFlatpickrInstance(changes.value);
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.addInputListeners();
    }, 0);
  }
  // because the actual view may be delayed in loading (think projection into a tab pane)
  // and because we rely on a library that operates outside the Angular view of the world
  // we need to keep trying to load the library, until the relevant DOM is actually live
  ngAfterViewChecked() {
    if (!this.isFlatpickrLoaded()) {
      // @ts-ignore ts is unhappy with the below call to `flatpickr`
      this.flatpickrInstance = flatpickr(`#${this.id}-input`, this.flatpickrOptions);
      // if (and only if) the initialization succeeded, we can set the date values
      if (this.isFlatpickrLoaded()) {
        if (this.value.length > 0) {
          this.setDateValues(this.value);
        }
      }
    }
  }
  onFocus() {
    // Updates the month manually when calendar mode is range because month
    // will not update properly without manually updating them on focus.
    if (this.range) {
      if (this.rangeInput.input.nativeElement === document.activeElement && this.flatpickrInstance.selectedDates[1]) {
        const currentMonth = this.flatpickrInstance.selectedDates[1].getMonth();
        this.flatpickrInstance.changeMonth(currentMonth, false);
      } else if (this.input.input.nativeElement === document.activeElement && this.flatpickrInstance.selectedDates[0]) {
        const currentMonth = this.flatpickrInstance.selectedDates[0].getMonth();
        this.flatpickrInstance.changeMonth(currentMonth, false);
      }
    }
  }
  onFocusOut() {
    this.onTouched();
  }
  /**
   * Writes a value from the model to the component. Expects the value to be `null` or `(Date | string)[]`
   * @param value value received from the model
   */
  writeValue(value) {
    this.value = value;
    setTimeout(() => {
      if (this.isFlatpickrLoaded() && this.flatpickrInstance.config) {
        this.setDateValues(this.value);
      }
    });
  }
  /**
   * `ControlValueAccessor` method to programmatically disable the DatePicker.
   *
   * ex: `this.formGroup.get("myDatePicker").disable();`
   *
   * @param isDisabled `true` to disable the DatePicker
   */
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /**
   * Cleans up our flatpickr instance
   */
  ngOnDestroy() {
    if (!this.isFlatpickrLoaded()) {
      return;
    }
    this.flatpickrInstance.destroy();
  }
  /**
   * Handles the `valueChange` event from the primary/single input
   */
  onValueChange(event) {
    if (this.isFlatpickrLoaded()) {
      const date = this.flatpickrInstance.parseDate(event, this.dateFormat);
      if (this.range) {
        this.setDateValues([date, this.flatpickrInstance.selectedDates[1]]);
      } else {
        this.setDateValues([date]);
      }
      this.doSelect(this.flatpickrInstance.selectedDates);
    }
  }
  /**
   * Handles the `valueChange` event from the range input
   */
  onRangeValueChange(event) {
    if (this.isFlatpickrLoaded() && this.flatpickrInstance.isOpen) {
      const date = this.flatpickrInstance.parseDate(event, this.dateFormat);
      this.setDateValues([this.flatpickrInstance.selectedDates[0], date]);
      this.doSelect(this.flatpickrInstance.selectedDates);
    }
  }
  /**
   * Handles opening the calendar "properly" when the calendar icon is clicked.
   */
  openCalendar(datepickerInput) {
    if (this.range) {
      datepickerInput.input.nativeElement.click();
      // If the first input's calendar icon is clicked when calendar is in range mode, then
      // the month and year needs to be manually changed to the current selected month and
      // year otherwise the calendar view will not be updated upon opening.
      if (datepickerInput === this.input && this.flatpickrInstance.selectedDates[0]) {
        const currentMonth = this.flatpickrInstance.selectedDates[0].getMonth();
        this.flatpickrInstance.currentYear = this.flatpickrInstance.selectedDates[0].getFullYear();
        this.flatpickrInstance.changeMonth(currentMonth, false);
      }
    } else {
      // Single-mode flatpickr handles mousedown but not click, so nativeElement.click() won't
      // work when the calendar icon is clicked. In this case we simply use flatpickr.open().
      this.flatpickrInstance.open();
    }
  }
  updateCalendarListeners() {
    const calendarContainer = document.querySelectorAll(".flatpickr-calendar");
    Array.from(calendarContainer).forEach(calendar => {
      calendar.removeEventListener("click", this.preventCalendarClose);
      calendar.addEventListener("click", this.preventCalendarClose);
    });
  }
  /**
   * Handles the initialization of event listeners for the datepicker input and range input fields.
   */
  addInputListeners() {
    if (!this.isFlatpickrLoaded()) {
      return;
    }
    // Allows focus transition from the datepicker input or range input field to
    // flatpickr calendar using a keyboard.
    const addFocusCalendarListener = element => {
      element.addEventListener("keydown", event => {
        if (event.key === "Escape") {
          this.flatpickrInstance.close();
        }
        if (event.key === "ArrowDown") {
          if (!this.flatpickrInstance.isOpen) {
            this.flatpickrInstance.open();
          }
          const calendarContainer = this.flatpickrInstance.calendarContainer;
          const dayElement = calendarContainer && calendarContainer.querySelector(".flatpickr-day[tabindex]");
          if (dayElement) {
            dayElement.focus();
            // If the user manually inputs a value into the date field and presses arrow down,
            // datepicker input onchange will be triggered when focus is removed from it and
            // `flatpickrInstance.setDate` and `flatpickrInstance.changeMonth` will be invoked
            // which will automatically change focus to the beginning of the document.
            if (document.activeElement !== dayElement && this.flatpickrInstance.selectedDateElem) {
              this.flatpickrInstance.selectedDateElem.focus();
            }
          }
        }
      });
    };
    if (this.input && this.input.input) {
      addFocusCalendarListener(this.input.input.nativeElement);
    }
    if (this.rangeInput && this.rangeInput.input) {
      addFocusCalendarListener(this.rangeInput.input.nativeElement);
    }
  }
  /**
   * Resets the flatpickr instance while keeping the date values (or updating them if newDates is provided)
   *
   * Used to pick up input changes or locale changes.
   *
   * @param newDates An optional SimpleChange of date values
   */
  resetFlatpickrInstance(newDates) {
    if (this.isFlatpickrLoaded()) {
      let dates = this.flatpickrInstance.selectedDates;
      if (newDates && this.didDateValueChange(newDates.currentValue, newDates.previousValue)) {
        dates = newDates.currentValue;
      }
      // only reset the flatpickr instance on Input changes
      // @ts-ignore ts is unhappy with the below call to `flatpickr`
      this.flatpickrInstance = flatpickr(`#${this.id}-input`, this.flatpickrOptions);
      this.setDateValues(dates);
    }
  }
  /**
   * Carbon uses a number of specific classnames for parts of the flatpickr - this idempotent method applies them if needed.
   */
  updateClassNames() {
    if (!this.elementRef) {
      return;
    }
    // get all the possible flatpickrs in the document - we need to add classes to (potentially) all of them
    const calendarContainer = document.querySelectorAll(".flatpickr-calendar");
    const monthContainer = document.querySelectorAll(".flatpickr-month");
    const weekdaysContainer = document.querySelectorAll(".flatpickr-weekdays");
    const weekdayContainer = document.querySelectorAll(".flatpickr-weekday");
    const daysContainer = document.querySelectorAll(".flatpickr-days");
    const dayContainer = document.querySelectorAll(".flatpickr-day");
    // add classes to lists of elements
    const addClassIfNotExists = (classname, elementList) => {
      Array.from(elementList).forEach(element => {
        if (!element.classList.contains(classname)) {
          element.classList.add(classname);
        }
      });
    };
    // add classes (but only if they don't exist, small perf win)
    addClassIfNotExists("cds--date-picker__calendar", calendarContainer);
    addClassIfNotExists("cds--date-picker__month", monthContainer);
    addClassIfNotExists("cds--date-picker__weekdays", weekdaysContainer);
    addClassIfNotExists("cds--date-picker__days", daysContainer);
    // add weekday classes and format the text
    Array.from(weekdayContainer).forEach(element => {
      element.innerHTML = element.innerHTML.replace(/\s+/g, "");
      element.classList.add("cds--date-picker__weekday");
    });
    // add day classes and special case the "today" element based on `this.value`
    Array.from(dayContainer).forEach(element => {
      element.classList.add("cds--date-picker__day");
      if (!this.value) {
        return;
      }
      if (element.classList.contains("today") && this.value.length > 0) {
        element.classList.add("no-border");
      } else if (element.classList.contains("today") && this.value.length === 0) {
        element.classList.remove("no-border");
      }
    });
  }
  updateAttributes() {
    const calendarContainer = document.querySelectorAll(".flatpickr-calendar");
    Array.from(calendarContainer).forEach(calendar => {
      calendar.setAttribute("role", "region");
      calendar.setAttribute("aria-label", this.ariaLabel);
    });
  }
  /**
   * Applies the given date value array to both the flatpickr instance and the `input`(s)
   * @param dates the date values to apply
   */
  setDateValues(dates) {
    if (this.isFlatpickrLoaded()) {
      const singleInput = this.elementRef.nativeElement.querySelector(`#${this.id}-input`);
      const rangeInput = this.elementRef.nativeElement.querySelector(`#${this.id}-rangeInput`);
      // `flatpickrInstance.setDate` removes the focus on the selected date element and will
      // automatically change focus to the beginning of the document. If a selected date is
      // focused before `flatpickrInstance.setDate` is invoked then it should remain focused.
      let shouldRefocusDateElement = this.flatpickrInstance.selectedDateElem === document.activeElement;
      // set the date on the instance
      this.flatpickrInstance.setDate(dates);
      if (shouldRefocusDateElement) {
        this.flatpickrInstance.selectedDateElem.focus();
      }
      // we can either set a date value or an empty string, so we start with an empty string
      let singleDate = "";
      // if date is a string, parse and format
      if (typeof this.flatpickrInstance.selectedDates[0] === "string") {
        singleDate = this.flatpickrInstance.parseDate(this.flatpickrInstance.selectedDates[0], this.dateFormat);
        singleDate = this.flatpickrInstance.formatDate(singleDate, this.dateFormat);
        // if date is not a string we can assume it's a Date and we should format
      } else if (!!this.flatpickrInstance.selectedDates[0]) {
        singleDate = this.flatpickrInstance.formatDate(this.flatpickrInstance.selectedDates[0], this.dateFormat);
      }
      if (rangeInput) {
        // we can either set a date value or an empty string, so we start with an empty string
        let rangeDate = "";
        // if date is a string, parse and format
        if (typeof this.flatpickrInstance.selectedDates[1] === "string") {
          rangeDate = this.flatpickrInstance.parseDate(this.flatpickrInstance.selectedDates[1].toString(), this.dateFormat);
          rangeDate = this.flatpickrInstance.formatDate(rangeDate, this.dateFormat);
          // if date is not a string we can assume it's a Date and we should format
        } else if (!!this.flatpickrInstance.selectedDates[1]) {
          rangeDate = this.flatpickrInstance.formatDate(this.flatpickrInstance.selectedDates[1], this.dateFormat);
        }
        setTimeout(() => {
          // apply the values
          rangeInput.value = rangeDate;
          singleInput.value = singleDate;
        });
      }
    }
  }
  doSelect(selectedValue) {
    // In range mode, if a date is selected from the first calendar that is from the previous month,
    // the month will not be updated on the calendar until the calendar is re-opened.
    // This will make sure the calendar is updated with the correct month.
    if (this.range && this.flatpickrInstance.selectedDates[0]) {
      const currentMonth = this.flatpickrInstance.selectedDates[0].getMonth();
      // `flatpickrInstance.changeMonth` removes the focus on the selected date element and will
      // automatically change focus to the beginning of the document. If a selected date is
      // focused before `flatpickrInstance.changeMonth` is invoked then it should remain focused.
      let shouldRefocusDateElement = this.flatpickrInstance.selectedDateElem === document.activeElement;
      this.flatpickrInstance.changeMonth(currentMonth, false);
      if (shouldRefocusDateElement) {
        this.flatpickrInstance.selectedDateElem.focus();
      }
    }
    this.valueChange.emit(selectedValue);
    this.propagateChange(selectedValue);
  }
  didDateValueChange(currentValue, previousValue) {
    return currentValue[0] !== previousValue[0] || currentValue[1] !== previousValue[1];
  }
  /**
   * More advanced checking of the loaded state of flatpickr
   */
  isFlatpickrLoaded() {
    // cast the instance to a boolean, and some method that has to exist for the library to be loaded in this case `setDate`
    return !!this.flatpickrInstance && !!this.flatpickrInstance.setDate;
  }
  /**
   * Right arrow HTML passed to flatpickr
   */
  rightArrowHTML() {
    return `
			<svg width="16px" height="16px" viewBox="0 0 16 16">
				<polygon points="11,8 6,13 5.3,12.3 9.6,8 5.3,3.7 6,3 "/>
				<rect width="16" height="16" style="fill:none" />
			</svg>`;
  }
  /**
   * Left arrow HTML passed to flatpickr
   */
  leftArrowHTML() {
    return `
			<svg width="16px" height="16px" viewBox="0 0 16 16">
				<polygon points="5,8 10,3 10.7,3.7 6.4,8 10.7,12.3 10,13 "/>
				<rect width="16" height="16" style="fill:none" />
			</svg>`;
  }
}
DatePicker.datePickerCount = 0;
DatePicker.ɵfac = function DatePicker_Factory(t) {
  return new (t || DatePicker)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.I18n));
};
DatePicker.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DatePicker,
  selectors: [["cds-date-picker"], ["ibm-date-picker"]],
  viewQuery: function DatePicker_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
      i0.ɵɵviewQuery(_c1, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.input = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rangeInput = _t.first);
    }
  },
  hostBindings: function DatePicker_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("focusin", function DatePicker_focusin_HostBindingHandler() {
        return ctx.onFocus();
      })("focusout", function DatePicker_focusout_HostBindingHandler() {
        return ctx.onFocusOut();
      });
    }
  },
  inputs: {
    range: "range",
    dateFormat: "dateFormat",
    language: "language",
    label: "label",
    helperText: "helperText",
    rangeHelperText: "rangeHelperText",
    rangeLabel: "rangeLabel",
    placeholder: "placeholder",
    ariaLabel: "ariaLabel",
    inputPattern: "inputPattern",
    id: "id",
    value: "value",
    theme: "theme",
    disabled: "disabled",
    invalid: "invalid",
    invalidText: "invalidText",
    warn: "warn",
    warnText: "warnText",
    size: "size",
    rangeInvalid: "rangeInvalid",
    rangeInvalidText: "rangeInvalidText",
    rangeWarn: "rangeWarn",
    rangeWarnText: "rangeWarnText",
    skeleton: "skeleton",
    plugins: "plugins",
    flatpickrOptions: "flatpickrOptions"
  },
  outputs: {
    valueChange: "valueChange",
    onClose: "onClose"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DatePicker,
    multi: true
  }]), i0.ɵɵNgOnChangesFeature],
  decls: 6,
  vars: 21,
  consts: [[1, "cds--form-item"], [1, "cds--date-picker", 3, "ngClass"], [1, "cds--date-picker-container"], [3, "label", "placeholder", "pattern", "id", "size", "type", "hasIcon", "disabled", "invalid", "invalidText", "warn", "warnText", "skeleton", "helperText", "valueChange", "click"], ["input", ""], ["class", "cds--date-picker-container", 4, "ngIf"], ["rangeInput", ""]],
  template: function DatePicker_Template(rf, ctx) {
    if (rf & 1) {
      const _r6 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "cds-date-picker-input", 3, 4);
      i0.ɵɵlistener("valueChange", function DatePicker_Template_cds_date_picker_input_valueChange_3_listener($event) {
        return ctx.onValueChange($event);
      })("click", function DatePicker_Template_cds_date_picker_input_click_3_listener() {
        i0.ɵɵrestoreView(_r6);
        const _r0 = i0.ɵɵreference(4);
        return i0.ɵɵresetView(ctx.openCalendar(_r0));
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(5, DatePicker_div_5_Template, 3, 14, "div", 5);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction4(16, _c2, ctx.range, !ctx.range, ctx.theme === "light", ctx.skeleton));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("label", ctx.label)("placeholder", ctx.placeholder)("pattern", ctx.inputPattern)("id", ctx.id + "-input")("size", ctx.size)("type", ctx.range ? "range" : "single")("hasIcon", ctx.range ? false : true)("disabled", ctx.disabled)("invalid", ctx.invalid)("invalidText", ctx.invalidText)("warn", ctx.warn)("warnText", ctx.warnText)("skeleton", ctx.skeleton)("helperText", ctx.helperText);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.range);
    }
  },
  dependencies: [i2.NgClass, i2.NgIf, i3.DatePickerInput],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DatePicker, [{
    type: Component,
    args: [{
      selector: "cds-date-picker, ibm-date-picker",
      template: `
	<div class="cds--form-item">
		<div
			class="cds--date-picker"
			[ngClass]="{
				'cds--date-picker--range' : range,
				'cds--date-picker--single' : !range,
				'cds--date-picker--light' : theme === 'light',
				'cds--skeleton' : skeleton
			}">
			<div class="cds--date-picker-container">
				<cds-date-picker-input
					#input
					[label]="label"
					[placeholder]="placeholder"
					[pattern]="inputPattern"
					[id]="id + '-input'"
					[size]="size"
					[type]="(range ? 'range' : 'single')"
					[hasIcon]="(range ? false : true)"
					[disabled]="disabled"
					[invalid]="invalid"
					[invalidText]="invalidText"
					[warn]="warn"
					[warnText]="warnText"
					[skeleton]="skeleton"
					[helperText]="helperText"
					(valueChange)="onValueChange($event)"
					(click)="openCalendar(input)">
				</cds-date-picker-input>
			</div>

			<div *ngIf="range" class="cds--date-picker-container">
				<cds-date-picker-input
					#rangeInput
					[label]="rangeLabel"
					[placeholder]="placeholder"
					[pattern]="inputPattern"
					[id]="id + '-rangeInput'"
					[size]="size"
					[type]="(range ? 'range' : 'single')"
					[hasIcon]="(range ? true : null)"
					[disabled]="disabled"
					[invalid]="rangeInvalid"
					[invalidText]="rangeInvalidText"
					[warn]="rangeWarn"
					[warnText]="rangeWarnText"
					[skeleton]="skeleton"
					[helperText]="rangeHelperText"
					(valueChange)="onRangeValueChange($event)"
					(click)="openCalendar(rangeInput)">
				</cds-date-picker-input>
			</div>
		</div>
	</div>
	`,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: DatePicker,
        multi: true
      }],
      encapsulation: ViewEncapsulation.None
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1.I18n
    }];
  }, {
    range: [{
      type: Input
    }],
    dateFormat: [{
      type: Input
    }],
    language: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    helperText: [{
      type: Input
    }],
    rangeHelperText: [{
      type: Input
    }],
    rangeLabel: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    inputPattern: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    invalid: [{
      type: Input
    }],
    invalidText: [{
      type: Input
    }],
    warn: [{
      type: Input
    }],
    warnText: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    rangeInvalid: [{
      type: Input
    }],
    rangeInvalidText: [{
      type: Input
    }],
    rangeWarn: [{
      type: Input
    }],
    rangeWarnText: [{
      type: Input
    }],
    skeleton: [{
      type: Input
    }],
    plugins: [{
      type: Input
    }],
    flatpickrOptions: [{
      type: Input
    }],
    input: [{
      type: ViewChild,
      args: ["input", {
        static: true
      }]
    }],
    rangeInput: [{
      type: ViewChild,
      args: ["rangeInput"]
    }],
    valueChange: [{
      type: Output
    }],
    onClose: [{
      type: Output
    }],
    onFocus: [{
      type: HostListener,
      args: ["focusin"]
    }],
    onFocusOut: [{
      type: HostListener,
      args: ["focusout"]
    }]
  });
})();
class DatePickerModule {}
DatePickerModule.ɵfac = function DatePickerModule_Factory(t) {
  return new (t || DatePickerModule)();
};
DatePickerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DatePickerModule
});
DatePickerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, DatePickerInputModule, UtilsModule, I18nModule, DatePickerInputModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DatePickerModule, [{
    type: NgModule,
    args: [{
      declarations: [DatePicker],
      exports: [DatePicker, DatePickerInputModule],
      imports: [CommonModule, DatePickerInputModule, UtilsModule, I18nModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DatePicker, DatePickerModule };
