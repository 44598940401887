import { KeyValue } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-otp-form',
  templateUrl: './otp-form.component.html',
  styleUrl: './otp-form.component.scss',
})
export class OtpFormComponent implements OnInit, OnDestroy {
  @Input() invalid = false;
  @Input() loading = false;

  @Output() onResend = new EventEmitter<void>();
  @Output() submit = new EventEmitter<string>();

  public form: FormGroup;
  public otpTimeout = 30;

  private otpTimeoutInterval: any;

  constructor(fb: FormBuilder) {
    this.form = fb.group({
      otpInput1: ['', Validators.required],
      otpInput2: ['', Validators.required],
      otpInput3: ['', Validators.required],
      otpInput4: ['', Validators.required],
    });
    this.startTimeoutForOTP();
  }

  ngOnInit(): void {
    if (!this.loading && !this.invalid)
      setTimeout(() => document.getElementById('otpInput1')?.focus());
  }

  /* ================ */
  /* USER_INTERACTION */
  /* ================ */
  public onInput(control: KeyValue<string, AbstractControl>, e: Event) {
    const length = control.key.length;
    const inputOrder = control.key[length - 1];
    const isNotEmpty = control.value.value.length === 1;

    if (+inputOrder === 4 && isNotEmpty) {
      document.getElementById(control.key)?.blur();
      this.onSubmit();
    } else {
      if (isNotEmpty) {
        const inputID = control.key.substring(0, length - 1);
        const nextEl: any = document.getElementById(
          inputID + (+inputOrder + 1)
        );
        this.setFocus(nextEl);
      }
    }
  }

  public onBackspace(control: KeyValue<string, AbstractControl>) {
    const length = control.key.length;
    const inputOrder = control.key[length - 1];
    if (+inputOrder > 1) {
      const inputID = control.key.substring(0, length - 1);
      const prevEl: any = document.getElementById(inputID + (+inputOrder - 1));
      if (!control.value.value.length) {
        this.setFocus(prevEl);
      } else this.form.patchValue({ [control.key]: '' });
    }
  }

  public onSubmit(): void {
    if (this.form.invalid) return;
    const otp = Object.values(this.form.value).join('');
    this.submit.emit(otp);
  }

  public resend(): void {
    this.onResend.emit();
    this.otpTimeout = 30;
    clearInterval(this.otpTimeoutInterval)
    this.startTimeoutForOTP()
  }

  /* ============== */
  /* HELPER_METHODS */
  /* ============== */
  private startTimeoutForOTP(): void {
    this.otpTimeoutInterval = setInterval(() => {
      if (this.otpTimeout > 0) this.otpTimeout--;
      else clearInterval(this.otpTimeoutInterval);
    }, 1000);
  }

  private setFocus(el: HTMLInputElement): void {
    el.focus();
    el.select();
  }

  ngOnDestroy(): void {
    clearInterval(this.otpTimeoutInterval);
  }
}
