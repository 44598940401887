<!-- input label -->
<label class="label" [for]="inputId">
  {{ label || "UPLOAD-FILE" | translate }}
</label>
<!-- input container -->
<div
  class="single-file-input-upload"
  [ngClass]="{ disabled, 'fade-in-out': loading, invalid }">
  <!-- placeholder -->
  <span class="placeholder truncate" [ngClass]="{ fulfilled: files[0] }">
    {{ (loading ? ("UPLOADING" | translate) + files[0]?.name : files[0]?.name || placeholder || "UPLOAD-FILE") | translate }}
  </span>
  <!-- upload button/input container -->
  <label class="upload-btn" [for]="inputId">
    <!-- title -->
    <span>{{
      (files[0] ? "UPLOAD-NEW-FILE" : "UPLOAD-FILE") | translate
      }}</span>
    <!-- input -->
    <input
      [id]="inputId"
      type="file"
      accept="image/*,application/pdf"
      (change)="onFileSelected($event)"
      (cancel)="markAsTouched()" />
  </label>
</div>
<!-- caption ( allowed files & sizes indicator ) -->
<p *ngIf="!invalid && !hideCaption" class="txt-caption input-caption">
  {{ "UPLOAD-INPUT-CAPTION" | translate }}
</p>
<!-- error message ( invalidity indicator ) -->
<p *ngIf="invalid" class="txt-caption input-caption txt-caption-danger">
  {{ "FORM-ERRORS.REQUIRED" || invalidTxt | translate }}
</p>
