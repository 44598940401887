<ul class="nav-container">
  <a class="nav-item" routerLink="/home" routerLinkActive="nav-item-active">
    <i class="fi-home"></i>
    {{ "NAV.HOME" | translate }}
  </a>
  <a
    *ngIf="isCustomer"
    class="nav-item"
    routerLink="/requests"
    routerLinkActive="nav-item-active"
  >
    <i class="fi-request"></i>
    {{ "NAV.ORDERS" | translate }}
  </a>
  <a
    *ngIf="!isCustomer"
    class="nav-item"
    routerLink="/offers"
    routerLinkActive="nav-item-active"
  >
    <i class="fi-request"></i>
    {{ "NAV.OFFERS" | translate }}
  </a>
  <a class="nav-item" routerLink="/payments" routerLinkActive="nav-item-active">
    <i class="fi-money"></i>
    {{ "NAV.PAYMENT" | translate }}
  </a>
  <!-- <a class="nav-item" routerLink="/login" routerLinkActive="nav-item-active">
    <i class="fi-message"></i>
    {{ "NAV.MESSAGES" | translate }}
  </a> -->
  <a class="nav-item" routerLink="/profile" routerLinkActive="nav-item-active">
    <i class="fi-profile"></i>
    {{ "NAV.ACCOUNT" | translate }}
  </a>
</ul>
