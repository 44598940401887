<i class="fi-menu menu-icon" (click)="isOpen = true"></i>
<div
  class="overlay {{ isOpen ? 'menu-open-overlay' : '' }}"
  (click)="isOpen = false"
></div>
<div class="menu {{ isOpen ? 'menu-open' : '' }} flx flx-col gap-12">
  <div class="flx-align-c flx-sb mb-12">
    <h6 class="txt-md fw-800 txt-gradient">{{ "MENU" | translate }}</h6>
    <button class="lang-switch flx-align-c gap-2" (click)="switchLang()">
      <p class="txt-xs fw-500">
        {{ "LANG" | translate }}
      </p>
      <i class="fi-globe txt-md"></i>
    </button>
  </div>

  <a (click)="isOpen = false"
    class="menu-item txt-caption txt-caption-black flx-align-c gap-4"
    routerLink="/home"
    routerLinkActive="menu-item-active"
  >
    <i class="fi-home"></i>
    {{ "NAV.HOME" | translate }}
  </a>

  <a (click)="isOpen = false"
    class="menu-item txt-caption txt-caption-black flx-align-c gap-4"
    routerLink="/payments"
    routerLinkActive="menu-item-active"
  >
    <i class="fi-money"></i>
    {{ "NAV.PAYMENT" | translate }}
  </a>

  <a (click)="isOpen = false"
    class="menu-item txt-caption txt-caption-black flx-align-c gap-4"
    routerLink="/profile"
    routerLinkActive="menu-item-active"
  >
    <i class="fi-profile"></i>
    {{ "NAV.ACCOUNT" | translate }}
  </a>

  <button (click)="isOpen = false" *ngIf="isLoggedIn" class="flx-align-c gap-4 txt-caption txt-caption-black menu-item logout-btn" (click)="logout()">
    <i class="fi-logout"></i>
    {{ "LOGOUT" | translate }}
  </button>

  <div class="menu-footer flx-col gap-12">
    <img class="logo-gray" [src]="'assets/svg/logo'+(lang == 'en' ? '-ltr' : '')+'.svg'" alt="Falak Logo - شعار فلك">
    <div class="txt-caption">{{'COPY-RIGHTS' | translate}}</div>
  </div>
</div>
