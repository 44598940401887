import { Component, Input } from '@angular/core';
import {
  Request,
  RequestStatus,
} from '../../../core/models/requests.model';
import { StatusColor } from '../../../core/models/global.model';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'app-request-card',
  templateUrl: './request-card.component.html',
  styleUrl: './request-card.component.scss',
})
export class RequestCardComponent {
  @Input() request!: Request;
  
  public isCustomer: boolean;

  constructor(userService: UserService) {
    this.isCustomer = userService.getUserInfo()?.role === 'customer';
  }

  public getRequestStatusColor(status: RequestStatus): StatusColor {
    return status == 'completed'
      ? 'success'
      : status == 'going'
      ? 'info'
      : 'neutral';
  }
}
