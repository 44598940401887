import * as i0 from '@angular/core';
import { Directive, Input, Output, Injectable, EventEmitter, TemplateRef, Component, ContentChild, ViewChild, HostBinding, HostListener, ViewChildren, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { Subscription, of, fromEvent, isObservable, Observable } from 'rxjs';
import * as i2 from 'carbon-components-angular/utils';
import { closestAttr, hasScrollableParents, getScrollableParents, UtilsModule } from 'carbon-components-angular/utils';
import * as i1$1 from 'carbon-components-angular/i18n';
import { I18nModule } from 'carbon-components-angular/i18n';
import { position } from '@carbon/utils-position';
import * as i1 from 'carbon-components-angular/placeholder';
import { PlaceholderModule } from 'carbon-components-angular/placeholder';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i5 from 'carbon-components-angular/icon';
import { IconModule } from 'carbon-components-angular/icon';
import { debounceTime, map, filter, first } from 'rxjs/operators';

/**
 * A component that intends to be used within `Dropdown` must provide an implementation that extends this base class.
 * It also must provide the base class in the `@Component` meta-data.
 * ex: `providers: [{provide: AbstractDropdownView, useExisting: forwardRef(() => MyDropdownView)}]`
 */
const _c0 = ["dropdownButton"];
const _c1 = ["dropdownMenu"];
function Dropdown_label_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r13 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r13.label);
  }
}
function Dropdown_label_0_2_ng_template_0_Template(rf, ctx) {}
function Dropdown_label_0_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Dropdown_label_0_2_ng_template_0_Template, 0, 0, "ng-template", 16);
  }
  if (rf & 2) {
    const ctx_r14 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r14.label);
  }
}
const _c2 = a0 => ({
  "cds--label--disabled": a0
});
function Dropdown_label_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 15);
    i0.ɵɵtemplate(1, Dropdown_label_0_ng_container_1_Template, 2, 1, "ng-container", 6)(2, Dropdown_label_0_2_Template, 1, 1, null, 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("for", ctx_r0.id)("ngClass", i0.ɵɵpureFunction1(4, _c2, ctx_r0.disabled));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.isTemplate(ctx_r0.label));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.isTemplate(ctx_r0.label));
  }
}
function Dropdown_div_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r17 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 17);
    i0.ɵɵlistener("click", function Dropdown_div_4_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r17);
      const ctx_r16 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r16.clearSelected());
    })("keydown.enter", function Dropdown_div_4_Template_div_keydown_enter_0_listener() {
      i0.ɵɵrestoreView(_r17);
      const ctx_r18 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r18.clearSelected());
    });
    i0.ɵɵtext(1);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(2, "svg", 18);
    i0.ɵɵelement(3, "path", 19);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("title", ctx_r2.clearText);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r2.getSelectedCount(), " ");
  }
}
function Dropdown_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 20);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, ctx_r3.getDisplayStringValue()));
  }
}
function Dropdown_6_ng_template_0_Template(rf, ctx) {}
function Dropdown_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Dropdown_6_ng_template_0_Template, 0, 0, "ng-template", 21);
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutletContext", ctx_r4.getRenderTemplateContext())("ngTemplateOutlet", ctx_r4.displayValue);
  }
}
function Dropdown__svg_svg_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 22);
  }
}
function Dropdown__svg_svg_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 23);
  }
}
const _c3 = a0 => ({
  "cds--list-box__menu-icon--open": a0
});
function Dropdown__svg_svg_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 24);
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(2, _c3, !ctx_r7.menuIsClosed));
    i0.ɵɵattribute("aria-label", ctx_r7.menuButtonLabel);
  }
}
function Dropdown_ng_content_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 0, ["*ngIf", "!menuIsClosed"]);
  }
}
function Dropdown_div_14_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r20 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r20.helperText);
  }
}
function Dropdown_div_14_2_ng_template_0_Template(rf, ctx) {}
function Dropdown_div_14_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Dropdown_div_14_2_ng_template_0_Template, 0, 0, "ng-template", 16);
  }
  if (rf & 2) {
    const ctx_r21 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r21.helperText);
  }
}
const _c4 = a0 => ({
  "cds--form__helper-text--disabled": a0
});
function Dropdown_div_14_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 25);
    i0.ɵɵtemplate(1, Dropdown_div_14_ng_container_1_Template, 2, 1, "ng-container", 6)(2, Dropdown_div_14_2_Template, 1, 1, null, 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c4, ctx_r10.disabled));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r10.isTemplate(ctx_r10.helperText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r10.isTemplate(ctx_r10.helperText));
  }
}
function Dropdown_div_15_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r23 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r23.invalidText);
  }
}
function Dropdown_div_15_2_ng_template_0_Template(rf, ctx) {}
function Dropdown_div_15_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Dropdown_div_15_2_ng_template_0_Template, 0, 0, "ng-template", 16);
  }
  if (rf & 2) {
    const ctx_r24 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r24.invalidText);
  }
}
function Dropdown_div_15_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 26);
    i0.ɵɵtemplate(1, Dropdown_div_15_ng_container_1_Template, 2, 1, "ng-container", 6)(2, Dropdown_div_15_2_Template, 1, 1, null, 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r11.isTemplate(ctx_r11.invalidText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r11.isTemplate(ctx_r11.invalidText));
  }
}
function Dropdown_div_16_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r26 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r26.warnText);
  }
}
function Dropdown_div_16_2_ng_template_0_Template(rf, ctx) {}
function Dropdown_div_16_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Dropdown_div_16_2_ng_template_0_Template, 0, 0, "ng-template", 16);
  }
  if (rf & 2) {
    const ctx_r27 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r27.warnText);
  }
}
function Dropdown_div_16_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 26);
    i0.ɵɵtemplate(1, Dropdown_div_16_ng_container_1_Template, 2, 1, "ng-container", 6)(2, Dropdown_div_16_2_Template, 1, 1, null, 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r12 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r12.isTemplate(ctx_r12.warnText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r12.isTemplate(ctx_r12.warnText));
  }
}
const _c5 = (a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12, a13) => ({
  "cds--dropdown": a0,
  "cds--multiselect": a1,
  "cds--multi-select--selected": a2,
  "cds--dropdown--light": a3,
  "cds--list-box--light": a4,
  "cds--list-box--inline": a5,
  "cds--skeleton": a6,
  "cds--dropdown--disabled cds--list-box--disabled": a7,
  "cds--dropdown--invalid": a8,
  "cds--dropdown--warning cds--list-box--warning": a9,
  "cds--dropdown--sm cds--list-box--sm": a10,
  "cds--dropdown--md cds--list-box--md": a11,
  "cds--dropdown--lg cds--list-box--lg": a12,
  "cds--list-box--expanded": a13
});
const _c6 = a0 => ({
  "a": a0
});
const _c7 = a0 => ({
  "cds--list-box--up": a0
});
const _c8 = ["*"];
const _c9 = ["list"];
const _c10 = ["listItem"];
function DropdownList_li_2_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9)(1, "label", 10);
    i0.ɵɵelement(2, "input", 11)(3, "span", 12);
    i0.ɵɵelementStart(4, "span", 13);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const item_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵattribute("data-contained-checkbox-state", item_r2.selected);
    i0.ɵɵadvance();
    i0.ɵɵproperty("checked", item_r2.selected)("disabled", item_r2.disabled);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(item_r2.content);
  }
}
function DropdownList_li_2_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const item_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(item_r2.content);
  }
}
function DropdownList_li_2__svg_svg_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 14);
  }
}
function DropdownList_li_2_6_ng_template_0_Template(rf, ctx) {}
const _c11 = a0 => ({
  item: a0
});
function DropdownList_li_2_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, DropdownList_li_2_6_ng_template_0_Template, 0, 0, "ng-template", 15);
  }
  if (rf & 2) {
    const item_r2 = i0.ɵɵnextContext().$implicit;
    const ctx_r8 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c11, item_r2))("ngTemplateOutlet", ctx_r8.listTpl);
  }
}
const _c12 = (a0, a1) => ({
  "cds--list-box__menu-item--active": a0,
  "cds--list-box__menu-item--highlighted": a1
});
function DropdownList_li_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 3);
    i0.ɵɵlistener("click", function DropdownList_li_2_Template_li_click_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r14);
      const item_r2 = restoredCtx.$implicit;
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.doClick($event, item_r2));
    });
    i0.ɵɵelementStart(1, "div", 4, 5);
    i0.ɵɵtemplate(3, DropdownList_li_2_div_3_Template, 6, 4, "div", 6)(4, DropdownList_li_2_ng_container_4_Template, 2, 1, "ng-container", 7)(5, DropdownList_li_2__svg_svg_5_Template, 1, 0, "svg", 8)(6, DropdownList_li_2_6_Template, 1, 4, null, 7);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    const i_r3 = ctx.index;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r1.getItemId(i_r3))("ngClass", i0.ɵɵpureFunction2(9, _c12, item_r2.selected, ctx_r1.highlightedItem === ctx_r1.getItemId(i_r3)));
    i0.ɵɵattribute("aria-selected", item_r2.selected)("title", ctx_r1.showTitles ? item_r2.content : null)("disabled", item_r2.disabled ? true : null);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", !ctx_r1.listTpl && ctx_r1.type === "multi");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.listTpl && ctx_r1.type === "single");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.listTpl && ctx_r1.type === "single");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.listTpl);
  }
}
class AbstractDropdownView {
  constructor() {
    /**
     * Specifies whether or not the `DropdownList` supports selecting multiple items as opposed to single
     * item selection.
     */
    this.type = "single";
    /**
     * Specifies the render size of the items within the `AbstractDropdownView`.
     */
    this.size = "md";
  }
  /**
   * The items to be displayed in the list within the `AbstractDropDownView`.
   */
  set items(value) {}
  get items() {
    return;
  }
  /**
   * Returns the `ListItem` that is subsequent to the selected item in the `DropdownList`.
   */
  getNextItem() {
    return;
  }
  /**
   * Returns a boolean if the currently selected item is preceded by another
   */
  hasNextElement() {
    return;
  }
  /**
   * Returns the `HTMLElement` for the item that is subsequent to the selected item.
   */
  getNextElement() {
    return;
  }
  /**
   * Returns the `ListItem` that precedes the selected item within `DropdownList`.
   */
  getPrevItem() {
    return;
  }
  /**
   * Returns a boolean if the currently selected item is followed by another
   */
  hasPrevElement() {
    return;
  }
  /**
   * Returns the `HTMLElement` for the item that precedes the selected item.
   */
  getPrevElement() {
    return;
  }
  /**
   * Returns the selected leaf level item(s) within the `DropdownList`.
   */
  getSelected() {
    return;
  }
  /**
   * Returns the `ListItem` that is selected within `DropdownList`.
   */
  getCurrentItem() {
    return;
  }
  /**
   * Returns the `HTMLElement` for the item that is selected within the `DropdownList`.
   */
  getCurrentElement() {
    return;
  }
  /**
   * Guaranteed to return the current items as an Array.
   */
  getListItems() {
    return;
  }
  /**
   * Transforms array input list of items to the correct state by updating the selected item(s).
   */
  propagateSelected(value) {}
  /**
   *
   * @param value value to filter the list by
   */
  filterBy(value) {}
  /**
   * Initializes focus in the list
   * In most cases this just calls `getCurrentElement().focus()`
   */
  initFocus() {}
  /**
   * Subscribe the function passed to an internal observable that will resolve once the items are ready
   */
  onItemsReady(subcription) {}
  /**
   * Reorder selected items bringing them to the top of the list
   */
  reorderSelected(moveFocus) {}
}
AbstractDropdownView.ɵfac = function AbstractDropdownView_Factory(t) {
  return new (t || AbstractDropdownView)();
};
AbstractDropdownView.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractDropdownView,
  selectors: [["", "cdsAbstractDropdownView", ""], ["", "ibmAbstractDropdownView", ""]],
  inputs: {
    items: "items"
  },
  outputs: {
    select: "select",
    blurIntent: "blurIntent"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractDropdownView, [{
    type: Directive,
    args: [{
      selector: "[cdsAbstractDropdownView], [ibmAbstractDropdownView]"
    }]
  }], null, {
    items: [{
      type: Input
    }],
    select: [{
      type: Output
    }],
    blurIntent: [{
      type: Output
    }]
  });
})();
const defaultOffset = {
  top: 0,
  left: 0
};
class DropdownService {
  constructor(placeholderService, animationFrameService) {
    this.placeholderService = placeholderService;
    this.animationFrameService = animationFrameService;
    /**
     * Maintains an Event Observable Subscription for the global requestAnimationFrame.
     * requestAnimationFrame is tracked only if the `Dropdown` is appended to the body otherwise we don't need it
     */
    this.animationFrameSubscription = new Subscription();
    this._offset = defaultOffset;
  }
  set offset(value) {
    this._offset = Object.assign({}, defaultOffset, value);
  }
  get offset() {
    return this._offset;
  }
  /**
   * Appends the menu to the body, or a `cds-placeholder` (if defined)
   *
   * @param parentRef container to position relative to
   * @param menuRef menu to be appended to body
   * @param classList any extra classes we should wrap the container with
   */
  appendToBody(parentRef, menuRef, classList) {
    // build the dropdown list container
    menuRef.style.display = "block";
    const dropdownWrapper = document.createElement("div");
    dropdownWrapper.className = `dropdown ${classList}`;
    dropdownWrapper.style.width = parentRef.offsetWidth + "px";
    dropdownWrapper.style.position = "absolute";
    dropdownWrapper.appendChild(menuRef);
    // append it to the placeholder
    if (this.placeholderService.hasPlaceholderRef()) {
      this.placeholderService.appendElement(dropdownWrapper);
      // or append it directly to the body
    } else {
      document.body.appendChild(dropdownWrapper);
    }
    this.menuInstance = dropdownWrapper;
    this.animationFrameSubscription = this.animationFrameService.tick.subscribe(() => {
      this.positionDropdown(parentRef, dropdownWrapper);
    });
    // run one position in sync, so we're less likely to have the view "jump" as we focus
    this.positionDropdown(parentRef, dropdownWrapper);
    return dropdownWrapper;
  }
  /**
   * Reattach the dropdown menu to the parent container
   * @param hostRef container to append to
   */
  appendToDropdown(hostRef) {
    // if the instance is already removed don't try and remove it again
    if (!this.menuInstance) {
      return;
    }
    const instance = this.menuInstance;
    const menu = instance.firstElementChild;
    // clean up the instance
    this.menuInstance = null;
    menu.style.display = "none";
    hostRef.appendChild(menu);
    this.animationFrameSubscription.unsubscribe();
    if (this.placeholderService.hasPlaceholderRef() && this.placeholderService.hasElement(instance)) {
      this.placeholderService.removeElement(instance);
    } else if (document.body.contains(instance)) {
      document.body.removeChild(instance);
    }
    return instance;
  }
  /**
   * position an open dropdown relative to the given parentRef
   */
  updatePosition(parentRef) {
    this.positionDropdown(parentRef, this.menuInstance);
  }
  ngOnDestroy() {
    this.animationFrameSubscription.unsubscribe();
  }
  positionDropdown(parentRef, menuRef) {
    if (!menuRef) {
      return;
    }
    let leftOffset = 0;
    const boxMenu = menuRef.querySelector(".cds--list-box__menu");
    if (boxMenu) {
      // If the parentRef and boxMenu are in a different left position relative to the
      // window, the the boxMenu position has already been flipped and a check needs to be done
      // to see if it needs to stay flipped.
      if (parentRef.getBoundingClientRect().left !== boxMenu.getBoundingClientRect().left) {
        // The getBoundingClientRect().right of the boxMenu if it were hypothetically flipped
        // back into the original position before the flip.
        const testBoxMenuRightEdgePos = parentRef.getBoundingClientRect().left - boxMenu.getBoundingClientRect().left + boxMenu.getBoundingClientRect().right;
        if (testBoxMenuRightEdgePos > (window.innerWidth || document.documentElement.clientWidth)) {
          leftOffset = parentRef.offsetWidth - boxMenu.offsetWidth;
        }
        // If it has not already been flipped, check if it is necessary to flip, ie. if the
        // boxMenu is outside of the right viewPort.
      } else if (boxMenu.getBoundingClientRect().right > (window.innerWidth || document.documentElement.clientWidth)) {
        leftOffset = parentRef.offsetWidth - boxMenu.offsetWidth;
      }
    }
    // If cds-placeholder has a parent with a position(relative|fixed|absolute) account for the parent offset
    const closestMenuWithPos = closestAttr("position", ["relative", "fixed", "absolute"], menuRef.parentElement);
    const topPos = closestMenuWithPos ? closestMenuWithPos.getBoundingClientRect().top * -1 : this.offset.top;
    const leftPos = closestMenuWithPos ? closestMenuWithPos.getBoundingClientRect().left * -1 : this.offset.left + leftOffset;
    let pos = position.findAbsolute(parentRef, menuRef, "bottom");
    pos = position.addOffset(pos, topPos, leftPos);
    position.setElement(menuRef, pos);
  }
}
DropdownService.ɵfac = function DropdownService_Factory(t) {
  return new (t || DropdownService)(i0.ɵɵinject(i1.PlaceholderService), i0.ɵɵinject(i2.AnimationFrameService));
};
DropdownService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: DropdownService,
  factory: DropdownService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropdownService, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.PlaceholderService
    }, {
      type: i2.AnimationFrameService
    }];
  }, null);
})();

/**
 * Drop-down lists enable users to select one or more items from a list.
 *
 * #### Opening behavior/List DOM placement
 * By default the dropdown will try to figure out the best placement for the dropdown list.
 *
 * If it's not contained within any scrolling elements, it will open inline, if it _is_
 * contained within a scrolling container it will try to open in the body, or an `cds-placeholder`.
 *
 * To control this behavior you can use the `appendInline` input:
 * - `[appendInline]="null"` is the default (auto detection)
 * - `[appendInline]="false"` will always append to the body/`cds-placeholder`
 * - `[appendInline]="true"` will always append inline (next to the dropdown button)
 *
 * Get started with importing the module:
 *
 * ```typescript
 * import { DropdownModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-dropdown--basic)
 */
class Dropdown {
  /**
   * Creates an instance of Dropdown.
   */
  constructor(elementRef, i18n, dropdownService, elementService) {
    this.elementRef = elementRef;
    this.i18n = i18n;
    this.dropdownService = dropdownService;
    this.elementService = elementService;
    this.id = `dropdown-${Dropdown.dropdownCount++}`;
    /**
     * Value displayed if no item is selected.
     */
    this.placeholder = "";
    /**
     * The selected value from the `Dropdown`. Can be a string or template.
     */
    this.displayValue = "";
    /**
     * Sets the optional clear button tooltip text.
     */
    this.clearText = this.i18n.get().DROPDOWN.CLEAR;
    /**
     * Size to render the dropdown field.
     */
    this.size = "md";
    /**
     * Defines whether or not the `Dropdown` supports selecting multiple items as opposed to single
     * item selection.
     */
    this.type = "single";
    /**
     * @deprecated since v5 - Use `cdsLayer` directive instead
     * `light` or `dark` dropdown theme
     */
    this.theme = "dark";
    /**
     * Set to `true` to disable the dropdown.
     */
    this.disabled = false;
    /**
     * Set to `true` for a loading dropdown.
     */
    this.skeleton = false;
    /**
     * Set to `true` for an inline dropdown.
     */
    this.inline = false;
    /**
     * Set to `true` for a dropdown without arrow key activation.
     */
    this.disableArrowKeys = false;
    /**
     * Set to `true` for invalid state.
     */
    this.invalid = false;
    /**
      * Set to `true` to show a warning (contents set by warningText)
      */
    this.warn = false;
    /**
     * set to `true` to place the dropdown view inline with the component
     */
    this.appendInline = null;
    /**
     * Specify feedback (mode) of the selection.
     * `top`: selected item jumps to top
     * `fixed`: selected item stays at it's position
     * `top-after-reopen`: selected item jump to top after reopen dropdown
     */
    this.selectionFeedback = "top-after-reopen";
    /**
     * Accessible label for the button that opens the dropdown list.
     * Defaults to the `DROPDOWN.OPEN` value from the i18n service.
     */
    this.menuButtonLabel = this.i18n.get().DROPDOWN.OPEN;
    /**
     * Provides the label for the "# selected" text.
     * Defaults to the `DROPDOWN.SELECTED` value from the i18n service.
     */
    this.selectedLabel = this.i18n.get().DROPDOWN.SELECTED;
    /**
     * Emits selection events.
     */
    this.selected = new EventEmitter();
    /**
     * Emits event notifying to other classes that the `Dropdown` has been closed (collapsed).
     */
    this.onClose = new EventEmitter();
    /**
     * Emits event notifying to other classes that the `Dropdown` has been closed (collapsed).
     */
    this.close = new EventEmitter();
    this.hostClass = true;
    /**
     * Set to `true` if the dropdown is closed (not expanded).
     */
    this.menuIsClosed = true;
    /**
     * controls whether the `drop-up` class is applied
     */
    this._dropUp = false;
    // .bind creates a new function, so we declare the methods below
    // but .bind them up here
    this.noop = this._noop.bind(this);
    this.outsideClick = this._outsideClick.bind(this);
    this.outsideKey = this._outsideKey.bind(this);
    this.keyboardNav = this._keyboardNav.bind(this);
    this.visibilitySubscription = new Subscription();
    this.onTouchedCallback = this._noop;
    // primarily used to capture and propagate input to `writeValue` before the content is available
    this._writtenValue = [];
    /**
     * function passed in by `registerOnChange`
     */
    this.propagateChange = _ => {};
  }
  get writtenValue() {
    return this._writtenValue;
  }
  set writtenValue(val) {
    if (val && val.length === 0) {
      this.clearSelected();
    }
    this._writtenValue = val;
  }
  /**
   * Updates the `type` property in the `@ContentChild`.
   * The `type` property specifies whether the `Dropdown` allows single selection or multi selection.
   */
  ngOnInit() {
    if (this.view) {
      this.view.type = this.type;
    }
  }
  /**
   * Initializes classes and subscribes to events for single or multi selection.
   */
  ngAfterContentInit() {
    if (!this.view) {
      return;
    }
    if (this.writtenValue && this.writtenValue.length || typeof this.writtenValue === "number") {
      this.writeValue(this.writtenValue);
    }
    this.view.type = this.type;
    this.view.size = this.size;
    // function to check if the event is organic (isUpdate === false) or programmatic
    const isUpdate = event => event && event.isUpdate;
    this.view.select.subscribe(event => {
      if (this.type === "single" && !isUpdate(event) && !Array.isArray(event)) {
        this.closeMenu();
        if (event.item && event.item.selected) {
          if (this.itemValueKey) {
            this.propagateChange(event.item[this.itemValueKey]);
          } else {
            this.propagateChange(event.item);
          }
        } else {
          this.propagateChange(null);
        }
      }
      if (this.type === "multi" && !isUpdate(event)) {
        // if we have a `value` selector and selected items map them appropriately
        if (this.itemValueKey && this.view.getSelected()) {
          const values = this.view.getSelected().map(item => item[this.itemValueKey]);
          this.propagateChange(values);
          // otherwise just pass up the values from `getSelected`
        } else {
          this.propagateChange(this.view.getSelected());
        }
      }
      // only emit selected for "organic" selections
      if (!isUpdate(event)) {
        this.checkForReorder();
        this.selected.emit(event);
      }
    });
  }
  ngAfterViewInit() {
    // if appendInline is default valued (null) we should:
    // 1. if there are scrollable parents (not including body) don't append inline
    //    this should also cover the case where the dropdown is in a modal
    //    (where we _do_ want to append to the placeholder)
    if (this.appendInline === null && hasScrollableParents(this.elementRef.nativeElement)) {
      this.appendInline = false;
      // 2. otherwise we should append inline
    } else if (this.appendInline === null) {
      this.appendInline = true;
    }
    this.checkForReorder();
  }
  /**
   * Removing the `Dropdown` from the body if it is appended to the body.
   */
  ngOnDestroy() {
    if (!this.appendInline) {
      this._appendToDropdown();
    }
  }
  /**
   * Propagates the injected `value`.
   */
  writeValue(value) {
    // cache the written value so we can use it in `AfterContentInit`
    this.writtenValue = value;
    this.view.onItemsReady(() => {
      // propagate null/falsey as an array (deselect everything)
      if (!value) {
        this.view.propagateSelected([value]);
      } else if (this.type === "single") {
        if (this.itemValueKey) {
          // clone the specified item and update its state
          const newValue = Object.assign({}, this.view.getListItems().find(item => item[this.itemValueKey] === value));
          newValue.selected = true;
          this.view.propagateSelected([newValue]);
        } else {
          // pass the singular value as an array of ListItem
          this.view.propagateSelected([value]);
        }
      } else {
        if (this.itemValueKey) {
          // clone the items and update their state based on the received value array
          // this way we don't lose any additional metadata that may be passed in via the `items` Input
          let newValues = [];
          for (const v of value) {
            for (const item of this.view.getListItems()) {
              if (item[this.itemValueKey] === v) {
                newValues.push(Object.assign({}, item, {
                  selected: true
                }));
              }
            }
          }
          this.view.propagateSelected(newValues);
        } else {
          // we can safely assume we're passing an array of `ListItem`s
          this.view.propagateSelected(value);
        }
      }
      this.checkForReorder();
    });
  }
  onBlur() {
    this.onTouchedCallback();
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  /**
   * Registering the function injected to control the touch use of the `Dropdown`.
   */
  registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }
  /**
   * `ControlValueAccessor` method to programmatically disable the dropdown.
   *
   * ex: `this.formGroup.get("myDropdown").disable();`
   *
   * @param isDisabled `true` to disable the input
   */
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  /**
   * Adds keyboard functionality for navigation, selection and closing of the `Dropdown`.
   */
  onKeyDown(event) {
    if (event.key === "Escape" && !this.menuIsClosed) {
      event.stopImmediatePropagation(); // don't unintentionally close other widgets that listen for Escape
    }

    if (event.key === "Escape") {
      event.preventDefault();
      this.closeMenu();
      this.dropdownButton.nativeElement.focus();
    } else if (this.menuIsClosed && (event.key === " " || event.key === "ArrowDown" || event.key === "ArrowUp")) {
      if (this.disableArrowKeys && (event.key === "ArrowDown" || event.key === "ArrowUp")) {
        return;
      }
      event.preventDefault();
      this.openMenu();
    }
    if (!this.menuIsClosed && event.key === "Tab" && this.dropdownMenu.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
    if (!this.menuIsClosed && event.key === "Tab" && event.shiftKey) {
      this.closeMenu();
    }
    if (this.type === "multi") {
      return;
    }
    if (this.menuIsClosed) {
      this.closedDropdownNavigation(event);
    }
  }
  closedDropdownNavigation(event) {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      this.view.getCurrentItem().selected = false;
      let item = this.view.getNextItem();
      if (item) {
        item.selected = true;
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      this.view.getCurrentItem().selected = false;
      let item = this.view.getPrevItem();
      if (item) {
        item.selected = true;
      }
    }
  }
  /**
   * Returns the display value if there is a selection and displayValue is set,
   * if there is just a selection the ListItem content property will be returned,
   * otherwise the placeholder will be returned.
   */
  getDisplayStringValue() {
    if (!this.view || this.skeleton) {
      return;
    }
    let selected = this.view.getSelected();
    if (selected.length && (!this.displayValue || !this.isRenderString())) {
      if (this.type === "multi") {
        return of(this.placeholder);
      } else {
        return of(selected[0].content);
      }
    } else if (selected.length && this.isRenderString()) {
      return of(this.displayValue);
    }
    return of(this.placeholder);
  }
  isRenderString() {
    return typeof this.displayValue === "string";
  }
  getRenderTemplateContext() {
    if (!this.view) {
      return;
    }
    let selected = this.view.getSelected();
    if (this.type === "multi") {
      return {
        items: selected
      };
    } else if (selected && selected.length > 0) {
      return {
        item: selected[0]
      }; // this is to be compatible with the dropdown-list template
    } else {
      return {};
    }
  }
  getSelectedCount() {
    if (this.view.getSelected()) {
      return this.view.getSelected().length;
    }
  }
  clearSelected() {
    if (this.disabled || this.getSelectedCount() === 0) {
      return;
    }
    for (const item of this.view.getListItems()) {
      item.selected = false;
    }
    this.selected.emit([]);
    this.propagateChange([]);
  }
  /**
   * Returns `true` if there is a value selected.
   */
  valueSelected() {
    if (this.view.getSelected()) {
      return true;
    }
    return false;
  }
  _noop() {}
  /**
   * Handles clicks outside of the `Dropdown`.
   */
  _outsideClick(event) {
    if (!this.elementRef.nativeElement.contains(event.target) &&
    // if we're appendToBody the list isn't within the _elementRef,
    // so we've got to check if our target is possibly in there too.
    !this.dropdownMenu.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
  }
  _outsideKey(event) {
    if (!this.menuIsClosed && event.key === "Tab" && this.dropdownMenu.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
  }
  /**
   * Handles keyboard events so users are controlling the `Dropdown` instead of unintentionally controlling outside elements.
   */
  _keyboardNav(event) {
    if (event.key === "Escape" && !this.menuIsClosed) {
      event.stopImmediatePropagation(); // don't unintentionally close modal if inside of it
    }

    if (event.key === "Escape") {
      event.preventDefault();
      this.closeMenu();
      this.dropdownButton.nativeElement.focus();
    } else if (!this.menuIsClosed && event.key === "Tab") {
      // this way focus will start on the next focusable item from the dropdown
      // not the top of the body!
      this.dropdownButton.nativeElement.focus();
      this.dropdownButton.nativeElement.dispatchEvent(new KeyboardEvent("keydown", {
        bubbles: true,
        cancelable: true,
        key: "Tab"
      }));
      this.closeMenu();
    }
  }
  /**
   * Creates the `Dropdown` list appending it to the dropdown parent object instead of the body.
   */
  _appendToDropdown() {
    this.dropdownService.appendToDropdown(this.elementRef.nativeElement);
    this.dropdownMenu.nativeElement.removeEventListener("keydown", this.keyboardNav, true);
  }
  /**
   * Creates the `Dropdown` list as an element that is appended to the DOM body.
   */
  _appendToBody() {
    const lightClass = this.theme === "light" ? " cds--list-box--light" : "";
    const expandedClass = !this.menuIsClosed ? " cds--list-box--expanded" : "";
    this.dropdownService.appendToBody(this.dropdownButton.nativeElement, this.dropdownMenu.nativeElement, `${this.elementRef.nativeElement.className}${lightClass}${expandedClass}`);
    this.dropdownMenu.nativeElement.addEventListener("keydown", this.keyboardNav, true);
  }
  /**
   * Detects whether or not the `Dropdown` list is visible within all scrollable parents.
   * This can be overridden by passing in a value to the `dropUp` input.
   */
  _shouldDropUp() {
    // check if dropdownMenu exists first.
    const menu = this.dropdownMenu && this.dropdownMenu.nativeElement.querySelector(".cds--list-box__menu");
    // check if menu exists first.
    const menuRect = menu && menu.getBoundingClientRect();
    if (menu && menuRect) {
      const scrollableParents = getScrollableParents(menu);
      return scrollableParents.reduce((shouldDropUp, parent) => {
        const parentRect = parent.getBoundingClientRect();
        const isBelowParent = !(menuRect.bottom <= parentRect.bottom);
        return shouldDropUp || isBelowParent;
      }, false);
    }
    return false;
  }
  /**
   * Expands the dropdown menu in the view.
   */
  openMenu() {
    // prevents the dropdown from opening when list of items is empty
    if (this.view.getListItems().length === 0) {
      return;
    }
    this._dropUp = false;
    this.menuIsClosed = false;
    // move the dropdown list to the body if we're not appending inline
    // and position it relative to the dropdown wrapper
    if (!this.appendInline) {
      const target = this.dropdownButton.nativeElement;
      const parent = this.elementRef.nativeElement;
      this.visibilitySubscription = this.elementService.visibility(target, parent).subscribe(value => {
        if (!value.visible) {
          this.closeMenu();
        }
      });
      this._appendToBody();
    }
    // set the dropdown menu to drop up if it's near the bottom of the screen
    // setTimeout lets us measure after it's visible in the DOM
    setTimeout(() => {
      if (this.dropUp === null || this.dropUp === undefined) {
        this._dropUp = this._shouldDropUp();
      }
    }, 0);
    // we bind noop to document.body.firstElementChild to allow safari to fire events
    // from document. Then we unbind everything later to keep things light.
    document.body.firstElementChild.addEventListener("click", this.noop, true);
    document.body.firstElementChild.addEventListener("keydown", this.noop, true);
    document.addEventListener("click", this.outsideClick, true);
    document.addEventListener("keydown", this.outsideKey, true);
    setTimeout(() => this.view.initFocus(), 0);
  }
  /**
   * Collapsing the dropdown menu and removing unnecessary `EventListeners`.
   */
  closeMenu() {
    // return early if the menu is already closed
    if (this.menuIsClosed) {
      return;
    }
    this.menuIsClosed = true;
    this.checkForReorder();
    this.onClose.emit();
    this.close.emit();
    // focus the trigger button when we close ...
    this.dropdownButton.nativeElement.focus();
    // remove the conditional once this api is settled and part of abstract-dropdown-view.class
    if (this.view["disableScroll"]) {
      this.view["disableScroll"]();
    }
    // move the list back in the component on close
    if (!this.appendInline) {
      this.visibilitySubscription.unsubscribe();
      this._appendToDropdown();
    }
    document.body.firstElementChild.removeEventListener("click", this.noop, true);
    document.body.firstElementChild.removeEventListener("keydown", this.noop, true);
    document.removeEventListener("click", this.outsideClick, true);
    document.removeEventListener("keydown", this.outsideKey, true);
  }
  /**
   * Controls toggling menu states between open/expanded and closed/collapsed.
   */
  toggleMenu() {
    if (this.menuIsClosed) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }
  isTemplate(value) {
    return value instanceof TemplateRef;
  }
  /**
   * Controls when it's needed to apply the selection feedback
   */
  checkForReorder() {
    const topAfterReopen = this.menuIsClosed && this.selectionFeedback === "top-after-reopen";
    if (this.type === "multi" && (topAfterReopen || this.selectionFeedback === "top")) {
      this.view.reorderSelected();
    }
  }
}
Dropdown.dropdownCount = 0;
Dropdown.ɵfac = function Dropdown_Factory(t) {
  return new (t || Dropdown)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$1.I18n), i0.ɵɵdirectiveInject(DropdownService), i0.ɵɵdirectiveInject(i2.ElementService));
};
Dropdown.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Dropdown,
  selectors: [["cds-dropdown"], ["ibm-dropdown"]],
  contentQueries: function Dropdown_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, AbstractDropdownView, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.view = _t.first);
    }
  },
  viewQuery: function Dropdown_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
      i0.ɵɵviewQuery(_c1, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdownButton = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdownMenu = _t.first);
    }
  },
  hostVars: 2,
  hostBindings: function Dropdown_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("keydown", function Dropdown_keydown_HostBindingHandler($event) {
        return ctx.onKeyDown($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵclassProp("cds--dropdown__wrapper", ctx.hostClass);
    }
  },
  inputs: {
    id: "id",
    label: "label",
    helperText: "helperText",
    placeholder: "placeholder",
    displayValue: "displayValue",
    clearText: "clearText",
    size: "size",
    type: "type",
    theme: "theme",
    disabled: "disabled",
    skeleton: "skeleton",
    inline: "inline",
    disableArrowKeys: "disableArrowKeys",
    invalid: "invalid",
    invalidText: "invalidText",
    warn: "warn",
    warnText: "warnText",
    appendInline: "appendInline",
    scrollableContainer: "scrollableContainer",
    itemValueKey: "itemValueKey",
    selectionFeedback: "selectionFeedback",
    menuButtonLabel: "menuButtonLabel",
    selectedLabel: "selectedLabel",
    dropUp: "dropUp"
  },
  outputs: {
    selected: "selected",
    onClose: "onClose",
    close: "close"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: Dropdown,
    multi: true
  }])],
  ngContentSelectors: _c8,
  decls: 17,
  vars: 37,
  consts: [["class", "cds--label", 3, "for", "ngClass", 4, "ngIf"], [1, "cds--list-box", 3, "ngClass"], ["type", "button", "aria-haspopup", "listbox", 1, "cds--list-box__field", 3, "id", "ngClass", "click", "blur"], ["dropdownButton", ""], ["class", "cds--list-box__selection cds--tag--filter cds--list-box__selection--multi", "tabindex", "0", 3, "title", "click", "keydown.enter", 4, "ngIf"], ["class", "cds--list-box__label", 4, "ngIf"], [4, "ngIf"], ["class", "cds--dropdown__invalid-icon", "cdsIcon", "warning--filled", "size", "16", 4, "ngIf"], ["cdsIcon", "warning--alt--filled", "size", "16", "class", "cds--list-box__invalid-icon cds--list-box__invalid-icon--warning", 4, "ngIf"], [1, "cds--list-box__menu-icon"], ["cdsIcon", "chevron--down", "size", "16", 3, "ngClass", 4, "ngIf"], [3, "ngClass"], ["dropdownMenu", ""], ["class", "cds--form__helper-text", 3, "ngClass", 4, "ngIf"], ["class", "cds--form-requirement", 4, "ngIf"], [1, "cds--label", 3, "for", "ngClass"], [3, "ngTemplateOutlet"], ["tabindex", "0", 1, "cds--list-box__selection", "cds--tag--filter", "cds--list-box__selection--multi", 3, "title", "click", "keydown.enter"], ["focusable", "false", "preserveAspectRatio", "xMidYMid meet", "role", "img", "xmlns", "http://www.w3.org/2000/svg", "width", "16", "height", "16", "viewBox", "0 0 16 16", "aria-hidden", "true", 2, "will-change", "transform"], ["d", "M12 4.7l-.7-.7L8 7.3 4.7 4l-.7.7L7.3 8 4 11.3l.7.7L8 8.7l3.3 3.3.7-.7L8.7 8z"], [1, "cds--list-box__label"], [3, "ngTemplateOutletContext", "ngTemplateOutlet"], ["cdsIcon", "warning--filled", "size", "16", 1, "cds--dropdown__invalid-icon"], ["cdsIcon", "warning--alt--filled", "size", "16", 1, "cds--list-box__invalid-icon", "cds--list-box__invalid-icon--warning"], ["cdsIcon", "chevron--down", "size", "16", 3, "ngClass"], [1, "cds--form__helper-text", 3, "ngClass"], [1, "cds--form-requirement"]],
  template: function Dropdown_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, Dropdown_label_0_Template, 3, 6, "label", 0);
      i0.ɵɵelementStart(1, "div", 1)(2, "button", 2, 3);
      i0.ɵɵlistener("click", function Dropdown_Template_button_click_2_listener($event) {
        return ctx.disabled ? $event.stopPropagation() : ctx.toggleMenu();
      })("blur", function Dropdown_Template_button_blur_2_listener() {
        return ctx.onBlur();
      });
      i0.ɵɵtemplate(4, Dropdown_div_4_Template, 4, 2, "div", 4)(5, Dropdown_span_5_Template, 3, 3, "span", 5)(6, Dropdown_6_Template, 1, 2, null, 6)(7, Dropdown__svg_svg_7_Template, 1, 0, "svg", 7)(8, Dropdown__svg_svg_8_Template, 1, 0, "svg", 8);
      i0.ɵɵelementStart(9, "span", 9);
      i0.ɵɵtemplate(10, Dropdown__svg_svg_10_Template, 1, 4, "svg", 10);
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(11, "div", 11, 12);
      i0.ɵɵtemplate(13, Dropdown_ng_content_13_Template, 1, 0, "ng-content", 6);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(14, Dropdown_div_14_Template, 3, 5, "div", 13)(15, Dropdown_div_15_Template, 3, 2, "div", 14)(16, Dropdown_div_16_Template, 3, 2, "div", 14);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.label && !ctx.skeleton);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunctionV(18, _c5, [ctx.type !== "multi", ctx.type === "multi", ctx.type === "multi" && ctx.getSelectedCount() > 0, ctx.theme === "light", ctx.theme === "light", ctx.inline, ctx.skeleton, ctx.disabled, ctx.invalid, ctx.warn, ctx.size === "sm", ctx.size === "md", ctx.size === "lg", !ctx.menuIsClosed]));
      i0.ɵɵadvance();
      i0.ɵɵproperty("id", ctx.id)("ngClass", i0.ɵɵpureFunction1(33, _c6, !ctx.menuIsClosed));
      i0.ɵɵattribute("aria-expanded", !ctx.menuIsClosed)("aria-disabled", ctx.disabled)("disabled", ctx.disabled ? true : null);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.type === "multi" && ctx.getSelectedCount() > 0);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.isRenderString());
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.isRenderString());
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.warn && ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.invalid && ctx.warn);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", !ctx.skeleton);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(35, _c7, ctx.dropUp !== null && ctx.dropUp !== undefined ? ctx.dropUp : ctx._dropUp));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", !ctx.menuIsClosed);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.helperText && !ctx.invalid && !ctx.warn && !ctx.skeleton);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.warn && ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.invalid && ctx.warn);
    }
  },
  dependencies: [i4.NgClass, i4.NgIf, i4.NgTemplateOutlet, i5.IconDirective, i4.AsyncPipe],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Dropdown, [{
    type: Component,
    args: [{
      selector: "cds-dropdown, ibm-dropdown",
      template: `
	<label
		*ngIf="label && !skeleton"
		[for]="id"
		class="cds--label"
		[ngClass]="{'cds--label--disabled': disabled}">
		<ng-container *ngIf="!isTemplate(label)">{{label}}</ng-container>
		<ng-template *ngIf="isTemplate(label)" [ngTemplateOutlet]="label"></ng-template>
	</label>
	<div
		class="cds--list-box"
		[ngClass]="{
			'cds--dropdown': type !== 'multi',
			'cds--multiselect': type === 'multi',
			'cds--multi-select--selected': type === 'multi' && getSelectedCount() > 0,
			'cds--dropdown--light': theme === 'light',
			'cds--list-box--light': theme === 'light',
			'cds--list-box--inline': inline,
			'cds--skeleton': skeleton,
			'cds--dropdown--disabled cds--list-box--disabled': disabled,
			'cds--dropdown--invalid': invalid,
			'cds--dropdown--warning cds--list-box--warning': warn,
			'cds--dropdown--sm cds--list-box--sm': size === 'sm',
			'cds--dropdown--md cds--list-box--md': size === 'md',
			'cds--dropdown--lg cds--list-box--lg': size === 'lg',
			'cds--list-box--expanded': !menuIsClosed
		}">
		<button
			#dropdownButton
			[id]="id"
			type="button"
			class="cds--list-box__field"
			[ngClass]="{'a': !menuIsClosed}"
			[attr.aria-expanded]="!menuIsClosed"
			[attr.aria-disabled]="disabled"
			aria-haspopup="listbox"
			(click)="disabled ? $event.stopPropagation() : toggleMenu()"
			(blur)="onBlur()"
			[attr.disabled]="disabled ? true : null">
			<div
				(click)="clearSelected()"
				(keydown.enter)="clearSelected()"
				*ngIf="type === 'multi' && getSelectedCount() > 0"
				class="cds--list-box__selection cds--tag--filter cds--list-box__selection--multi"
				tabindex="0"
				[title]="clearText">
				{{getSelectedCount()}}
				<svg
					focusable="false"
					preserveAspectRatio="xMidYMid meet"
					style="will-change: transform;"
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					viewBox="0 0 16 16"
					aria-hidden="true">
					<path d="M12 4.7l-.7-.7L8 7.3 4.7 4l-.7.7L7.3 8 4 11.3l.7.7L8 8.7l3.3 3.3.7-.7L8.7 8z"></path>
				</svg>
			</div>
			<span *ngIf="isRenderString()" class="cds--list-box__label">{{getDisplayStringValue() | async}}</span>
			<ng-template
				*ngIf="!isRenderString()"
				[ngTemplateOutletContext]="getRenderTemplateContext()"
				[ngTemplateOutlet]="displayValue">
			</ng-template>
			<svg
				*ngIf="!warn && invalid"
				class="cds--dropdown__invalid-icon"
				cdsIcon="warning--filled"
				size="16">
			</svg>
			<svg
				*ngIf="!invalid && warn"
				cdsIcon="warning--alt--filled"
				size="16"
				class="cds--list-box__invalid-icon cds--list-box__invalid-icon--warning">
			</svg>
			<span class="cds--list-box__menu-icon">
				<svg
					*ngIf="!skeleton"
					cdsIcon="chevron--down"
					size="16"
					[attr.aria-label]="menuButtonLabel"
					[ngClass]="{'cds--list-box__menu-icon--open': !menuIsClosed }">
				</svg>
			</span>
		</button>
		<div
			#dropdownMenu
			[ngClass]="{
				'cds--list-box--up': this.dropUp !== null && this.dropUp !== undefined ? dropUp : _dropUp
			}">
			<ng-content *ngIf="!menuIsClosed"></ng-content>
		</div>
	</div>
	<div
		*ngIf="helperText && !invalid && !warn && !skeleton"
		class="cds--form__helper-text"
		[ngClass]="{
			'cds--form__helper-text--disabled': disabled
		}">
		<ng-container *ngIf="!isTemplate(helperText)">{{helperText}}</ng-container>
		<ng-template *ngIf="isTemplate(helperText)" [ngTemplateOutlet]="helperText"></ng-template>
	</div>
	<div *ngIf="!warn && invalid" class="cds--form-requirement">
		<ng-container *ngIf="!isTemplate(invalidText)">{{ invalidText }}</ng-container>
		<ng-template *ngIf="isTemplate(invalidText)" [ngTemplateOutlet]="invalidText"></ng-template>
	</div>
	<div *ngIf="!invalid && warn" class="cds--form-requirement">
		<ng-container *ngIf="!isTemplate(warnText)">{{warnText}}</ng-container>
		<ng-template *ngIf="isTemplate(warnText)" [ngTemplateOutlet]="warnText"></ng-template>
	</div>
	`,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: Dropdown,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1$1.I18n
    }, {
      type: DropdownService
    }, {
      type: i2.ElementService
    }];
  }, {
    id: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    helperText: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    displayValue: [{
      type: Input
    }],
    clearText: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    skeleton: [{
      type: Input
    }],
    inline: [{
      type: Input
    }],
    disableArrowKeys: [{
      type: Input
    }],
    invalid: [{
      type: Input
    }],
    invalidText: [{
      type: Input
    }],
    warn: [{
      type: Input
    }],
    warnText: [{
      type: Input
    }],
    appendInline: [{
      type: Input
    }],
    scrollableContainer: [{
      type: Input
    }],
    itemValueKey: [{
      type: Input
    }],
    selectionFeedback: [{
      type: Input
    }],
    menuButtonLabel: [{
      type: Input
    }],
    selectedLabel: [{
      type: Input
    }],
    dropUp: [{
      type: Input
    }],
    selected: [{
      type: Output
    }],
    onClose: [{
      type: Output
    }],
    close: [{
      type: Output
    }],
    view: [{
      type: ContentChild,
      args: [AbstractDropdownView, {
        static: true
      }]
    }],
    dropdownButton: [{
      type: ViewChild,
      args: ["dropdownButton", {
        static: true
      }]
    }],
    dropdownMenu: [{
      type: ViewChild,
      args: ["dropdownMenu", {
        static: true
      }]
    }],
    hostClass: [{
      type: HostBinding,
      args: ["class.cds--dropdown__wrapper"]
    }],
    onKeyDown: [{
      type: HostListener,
      args: ["keydown", ["$event"]]
    }]
  });
})();

/**
 * returns an observable bound to keydown events that
 * filters to a single element where the first letter of
 * it's textContent matches the key pressed
 *
 * @param target element to watch
 * @param elements elements to search
 */
function watchFocusJump(target, elements) {
  return fromEvent(target, "keydown").pipe(debounceTime(150), map(ev => {
    let el = elements.find(itemEl => itemEl.textContent.trim().toLowerCase().startsWith(ev.key));
    if (el) {
      return el;
    }
  }), filter(el => !!el));
}
/** bundle of functions to aid in manipulating tree structures */
const treetools = {
  /** finds an item in a set of items and returns the item and path to the item as an array */
  find: function (items, itemToFind, path = []) {
    let found;
    for (let i of items) {
      if (i === itemToFind) {
        path.push(i);
        found = i;
      }
      if (i.items && !found) {
        path.push(i);
        found = this.find(i.items, itemToFind, path).found;
        if (!found) {
          path = [];
        }
      }
    }
    return {
      found,
      path
    };
  }
};

/**
 * ```html
 * <cds-dropdown-list [items]="listItems"></cds-dropdown-list>
 * ```
 * ```typescript
 * listItems = [
 * 	{
 * 		content: "item one",
 * 		selected: false
 * 	},
 * 	{
 * 		content: "item two",
 * 		selected: false,
 * 	},
 * 	{
 * 		content: "item three",
 * 		selected: false
 * 	},
 * 	{
 * 		content: "item four",
 * 		selected: false
 * 	}
 * ];
 * ```
 */
class DropdownList {
  /**
   * Creates an instance of `DropdownList`.
   */
  constructor(elementRef, i18n, appRef) {
    this.elementRef = elementRef;
    this.i18n = i18n;
    this.appRef = appRef;
    this.ariaLabel = this.i18n.get().DROPDOWN_LIST.LABEL;
    /**
     * Template to bind to items in the `DropdownList` (optional).
     */
    this.listTpl = null;
    /**
     * Event to emit selection of a list item within the `DropdownList`.
     */
    this.select = new EventEmitter();
    /**
     * Event to emit scroll event of a list within the `DropdownList`.
     */
    this.scroll = new EventEmitter();
    /**
     * Event to suggest a blur on the view.
     * Emits _after_ the first/last item has been focused.
     * ex.
     * ArrowUp -> focus first item
     * ArrowUp -> emit event
     *
     * When this event fires focus should be placed on some element outside of the list - blurring the list as a result
     */
    this.blurIntent = new EventEmitter();
    /**
     * Defines whether or not the `DropdownList` supports selecting multiple items as opposed to single
     * item selection.
     */
    this.type = "single";
    /**
     * Defines whether to show title attribute or not
     */
    this.showTitles = true;
    /**
     * Defines the rendering size of the `DropdownList` input component.
     */
    this.size = "md";
    this.listId = `listbox-${DropdownList.listCount++}`;
    this.highlightedItem = null;
    /**
     * Holds the list of items that will be displayed in the `DropdownList`.
     * It differs from the the complete set of items when filtering is used (but
     * it is always a subset of the total items in `DropdownList`).
     */
    this.displayItems = [];
    /**
     * Maintains the index for the selected item within the `DropdownList`.
     */
    this.index = -1;
    /**
     * Useful representation of the items, should be accessed via `getListItems`.
     */
    this._items = [];
  }
  /**
   * The list items belonging to the `DropdownList`.
   */
  set items(value) {
    if (isObservable(value)) {
      if (this._itemsSubscription) {
        this._itemsSubscription.unsubscribe();
      }
      this._itemsReady = new Observable(observer => {
        this._itemsSubscription = value.subscribe(v => {
          this.updateList(v);
          observer.next(true);
          observer.complete();
        });
      });
      this.onItemsReady(null);
    } else {
      this.updateList(value);
    }
    this._originalItems = value;
  }
  get items() {
    return this._originalItems;
  }
  /**
   * Retrieves array of list items and index of the selected item after view has rendered.
   * Additionally, any Observables for the `DropdownList` are initialized.
   */
  ngAfterViewInit() {
    this.index = this.getListItems().findIndex(item => item.selected);
    this.setupFocusObservable();
    setTimeout(() => {
      this.doEmitSelect(true);
    });
  }
  /**
   * Removes any Observables on destruction of the component.
   */
  ngOnDestroy() {
    if (this.focusJump) {
      this.focusJump.unsubscribe();
    }
    if (this._itemsSubscription) {
      this._itemsSubscription.unsubscribe();
    }
  }
  doEmitSelect(isUpdate = true) {
    if (this.type === "single") {
      this.select.emit({
        item: this._items.find(item => item.selected),
        isUpdate: isUpdate
      });
    } else {
      // abuse javascripts object mutability until we can break the API and switch to
      // { items: [], isUpdate: true }
      const selected = this.getSelected() || [];
      selected["isUpdate"] = isUpdate;
      this.select.emit(selected);
    }
  }
  getItemId(index) {
    return `${this.listId}-${index}`;
  }
  /**
   * Updates the displayed list of items and then retrieves the most current properties for the `DropdownList` from the DOM.
   */
  updateList(items) {
    this._items = items.map(item => Object.assign({}, item));
    this.displayItems = this._items;
    this.updateIndex();
    this.setupFocusObservable();
    this.doEmitSelect();
  }
  /**
   * Filters the items being displayed in the DOM list.
   */
  filterBy(query = "") {
    if (query) {
      this.displayItems = this.getListItems().filter(item => item.content.toLowerCase().includes(query.toLowerCase()));
      // Reset index if items were found
      // Prevent selecting index in list that are undefined.
      if (this.displayItems) {
        this.index = 0;
      }
    } else {
      this.displayItems = this.getListItems();
    }
    this.updateIndex();
  }
  /**
   * Initializes (or re-initializes) the Observable that handles switching focus to an element based on
   * key input matching the first letter of the item in the list.
   */
  setupFocusObservable() {
    if (!this.list) {
      return;
    }
    if (this.focusJump) {
      this.focusJump.unsubscribe();
    }
    let elList = Array.from(this.list.nativeElement.querySelectorAll("li"));
    this.focusJump = watchFocusJump(this.list.nativeElement, elList).subscribe(el => {
      el.focus();
    });
  }
  /**
   * Returns the `ListItem` that is subsequent to the selected item in the `DropdownList`.
   */
  getNextItem() {
    if (this.index < this.displayItems.length - 1) {
      this.index++;
    }
    return this.displayItems[this.index];
  }
  /**
   * Returns `true` if the selected item is not the last item in the `DropdownList`.
   */
  hasNextElement() {
    return this.index < this.displayItems.length - 1 && (!(this.index === this.displayItems.length - 2) || !this.displayItems[this.index + 1].disabled);
  }
  /**
   * Returns the `HTMLElement` for the item that is subsequent to the selected item.
   */
  getNextElement() {
    // Only return native elements if they are rendered
    const elemList = this.listElementList ? this.listElementList.toArray() : [];
    if (!elemList.length) {
      return null;
    }
    /**
     * Start checking from next index
     * Continue looping through the list until a non disabeled element is found or
     * end of list is reached
     */
    for (let i = this.index + 1; i < elemList.length; i++) {
      // If the values in the list are not disabled
      if (!this.displayItems[i].disabled) {
        this.index = i;
        return elemList[i].nativeElement;
      }
    }
    return elemList[this.index].nativeElement;
  }
  /**
   * Returns the `ListItem` that precedes the selected item within `DropdownList`.
   */
  getPrevItem() {
    if (this.index > 0) {
      this.index--;
    }
    return this.displayItems[this.index];
  }
  /**
   * Returns `true` if the selected item is not the first in the list.
   */
  hasPrevElement() {
    return this.index > 0 && (!(this.index === 1) || !this.displayItems[0].disabled);
  }
  /**
   * Returns the `HTMLElement` for the item that precedes the selected item.
   */
  getPrevElement() {
    // Only return native elements if they are rendered
    const elemList = this.listElementList ? this.listElementList.toArray() : [];
    if (!elemList.length) {
      return null;
    }
    /**
     * Start checking from next index
     * Continue looping through the list until a non disabeled element is found or
     * end of list is reached
     */
    for (let i = this.index - 1; i < this.index && i >= 0; i--) {
      // If the values in the list are not disabled
      if (!this.displayItems[i].disabled) {
        this.index = i;
        return elemList[i].nativeElement;
      }
    }
    return elemList[this.index].nativeElement;
  }
  /**
   * Returns the `ListItem` that is selected within `DropdownList`.
   */
  getCurrentItem() {
    if (this.index < 0) {
      return this.displayItems[0];
    }
    return this.displayItems[this.index];
  }
  /**
   * Returns the `HTMLElement` for the item that is selected within the `DropdownList`.
   */
  getCurrentElement() {
    if (this.index < 0) {
      return this.listElementList.first.nativeElement;
    }
    return this.listElementList.toArray()[this.index].nativeElement;
  }
  /**
   * Returns the items as an Array
   */
  getListItems() {
    return this._items;
  }
  /**
   * Returns a list containing the selected item(s) in the `DropdownList`.
   */
  getSelected() {
    let selected = this.getListItems().filter(item => item.selected);
    if (selected.length === 0) {
      return [];
    }
    return selected;
  }
  /**
   * Transforms array input list of items to the correct state by updating the selected item(s).
   */
  propagateSelected(value) {
    // if we get a non-array, log out an error (since it is one)
    if (!Array.isArray(value)) {
      console.error(`${this.constructor.name}.propagateSelected expects an Array<ListItem>, got ${JSON.stringify(value)}`);
    }
    this.onItemsReady(() => {
      // loop through the list items and update the `selected` state for matching items in `value`
      for (let oldItem of this.getListItems()) {
        // copy the item
        let tempOldItem = Object.assign({}, oldItem);
        // deleted selected because it's what we _want_ to change
        delete tempOldItem.selected;
        // stringify for compare
        tempOldItem = JSON.stringify(tempOldItem);
        for (let newItem of value) {
          // copy the item
          let tempNewItem = Object.assign({}, newItem);
          // deleted selected because it's what we _want_ to change
          delete tempNewItem.selected;
          // stringify for compare
          tempNewItem = JSON.stringify(tempNewItem);
          // do the compare
          if (tempOldItem.includes(tempNewItem)) {
            oldItem.selected = newItem.selected;
            // if we've found a matching item, we can stop looping
            break;
          } else {
            oldItem.selected = false;
          }
        }
      }
    });
  }
  /**
   * Initializes focus in the list, effectively a wrapper for `getCurrentElement().focus()`
   */
  initFocus() {
    if (this.index < 0) {
      this.updateIndex();
    }
    this.list.nativeElement.focus();
    setTimeout(() => {
      this.highlightedItem = this.getItemId(this.index);
    });
  }
  updateIndex() {
    // initialize index on the first selected item or
    // on the next non disabled item if no items are selected
    const selected = this.getSelected();
    if (selected.length) {
      this.index = this.displayItems.indexOf(selected[0]);
    } else if (this.hasNextElement()) {
      this.getNextElement();
    }
  }
  /**
   * Manages the keyboard accessibility for navigation and selection within a `DropdownList`.
   */
  navigateList(event) {
    if (event.key === "Enter" || event.key === " ") {
      if (this.listElementList.some(option => option.nativeElement === event.target)) {
        event.preventDefault();
      }
      if (event.key === "Enter") {
        this.doClick(event, this.getCurrentItem());
      }
    } else if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      event.preventDefault();
      if (event.key === "ArrowDown") {
        if (this.hasNextElement()) {
          this.getNextElement().scrollIntoView({
            block: "end"
          });
        } else {
          this.blurIntent.emit("bottom");
        }
      } else if (event.key === "ArrowUp") {
        if (this.hasPrevElement()) {
          this.getPrevElement().scrollIntoView();
        } else {
          this.blurIntent.emit("top");
        }
      }
      setTimeout(() => {
        this.highlightedItem = this.getItemId(this.index);
      });
    }
  }
  /**
   * Emits the selected item or items after a mouse click event has occurred.
   */
  doClick(event, item) {
    event.preventDefault();
    if (item && !item.disabled) {
      this.list.nativeElement.focus();
      if (this.type === "single") {
        item.selected = true;
        // reset the selection
        for (let otherItem of this.getListItems()) {
          if (item !== otherItem) {
            otherItem.selected = false;
          }
        }
      } else {
        item.selected = !item.selected;
      }
      this.index = this.displayItems.indexOf(item);
      this.highlightedItem = this.getItemId(this.index);
      this.doEmitSelect(false);
      this.appRef.tick();
    }
  }
  onItemFocus(index) {
    const element = this.listElementList.toArray()[index].nativeElement;
    element.classList.add("cds--list-box__menu-item--highlighted");
    element.tabIndex = 0;
  }
  onItemBlur(index) {
    const element = this.listElementList.toArray()[index].nativeElement;
    element.classList.remove("cds--list-box__menu-item--highlighted");
    element.tabIndex = -1;
  }
  /**
   * Emits the scroll event of the options list
   */
  emitScroll(event) {
    const atTop = event.srcElement.scrollTop === 0;
    const atBottom = event.srcElement.scrollHeight - event.srcElement.scrollTop === event.srcElement.clientHeight;
    const customScrollEvent = {
      atTop,
      atBottom,
      event
    };
    this.scroll.emit(customScrollEvent);
  }
  /**
   * Subscribe the function passed to an internal observable that will resolve once the items are ready
   */
  onItemsReady(subcription) {
    // this subscription will auto unsubscribe because of the `first()` pipe
    (this._itemsReady || of(true)).pipe(first()).subscribe(subcription);
  }
  reorderSelected(moveFocus = true) {
    this.displayItems = [...this.getSelected(), ...this.getListItems().filter(item => !item.selected)];
    if (moveFocus) {
      setTimeout(() => {
        this.updateIndex();
        this.highlightedItem = this.getItemId(this.index);
      });
    }
  }
}
DropdownList.listCount = 0;
DropdownList.ɵfac = function DropdownList_Factory(t) {
  return new (t || DropdownList)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$1.I18n), i0.ɵɵdirectiveInject(i0.ApplicationRef));
};
DropdownList.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DropdownList,
  selectors: [["cds-dropdown-list"], ["ibm-dropdown-list"]],
  viewQuery: function DropdownList_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c9, 7);
      i0.ɵɵviewQuery(_c10, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.list = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.listElementList = _t);
    }
  },
  inputs: {
    ariaLabel: "ariaLabel",
    items: "items",
    listTpl: "listTpl",
    type: "type",
    showTitles: "showTitles"
  },
  outputs: {
    select: "select",
    scroll: "scroll",
    blurIntent: "blurIntent"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: AbstractDropdownView,
    useExisting: DropdownList
  }])],
  decls: 3,
  vars: 4,
  consts: [["role", "listbox", "tabindex", "-1", 1, "cds--list-box__menu", "cds--multi-select", 3, "id", "scroll", "keydown"], ["list", ""], ["role", "option", "class", "cds--list-box__menu-item", 3, "id", "ngClass", "click", 4, "ngFor", "ngForOf"], ["role", "option", 1, "cds--list-box__menu-item", 3, "id", "ngClass", "click"], ["tabindex", "-1", 1, "cds--list-box__menu-item__option"], ["listItem", ""], ["class", "cds--form-item cds--checkbox-wrapper", 4, "ngIf"], [4, "ngIf"], ["cdsIcon", "checkmark", "size", "16", "class", "cds--list-box__menu-item__selected-icon", 4, "ngIf"], [1, "cds--form-item", "cds--checkbox-wrapper"], [1, "cds--checkbox-label"], ["type", "checkbox", "tabindex", "-1", 1, "cds--checkbox", 3, "checked", "disabled"], [1, "cds--checkbox-appearance"], [1, "cds--checkbox-label-text"], ["cdsIcon", "checkmark", "size", "16", 1, "cds--list-box__menu-item__selected-icon"], [3, "ngTemplateOutletContext", "ngTemplateOutlet"]],
  template: function DropdownList_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "ul", 0, 1);
      i0.ɵɵlistener("scroll", function DropdownList_Template_ul_scroll_0_listener($event) {
        return ctx.emitScroll($event);
      })("keydown", function DropdownList_Template_ul_keydown_0_listener($event) {
        return ctx.navigateList($event);
      });
      i0.ɵɵtemplate(2, DropdownList_li_2_Template, 7, 12, "li", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("id", ctx.listId);
      i0.ɵɵattribute("aria-label", ctx.ariaLabel)("aria-activedescendant", ctx.highlightedItem);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngForOf", ctx.displayItems);
    }
  },
  dependencies: [i4.NgClass, i4.NgForOf, i4.NgIf, i4.NgTemplateOutlet, i5.IconDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropdownList, [{
    type: Component,
    args: [{
      selector: "cds-dropdown-list, ibm-dropdown-list",
      template: `
		<ul
			#list
			[id]="listId"
			role="listbox"
			class="cds--list-box__menu cds--multi-select"
			(scroll)="emitScroll($event)"
			(keydown)="navigateList($event)"
			tabindex="-1"
			[attr.aria-label]="ariaLabel"
			[attr.aria-activedescendant]="highlightedItem">
			<li
				role="option"
				*ngFor="let item of displayItems; let i = index"
				(click)="doClick($event, item)"
				class="cds--list-box__menu-item"
				[attr.aria-selected]="item.selected"
				[id]="getItemId(i)"
				[attr.title]=" showTitles ? item.content : null"
				[attr.disabled]="item.disabled ? true : null"
				[ngClass]="{
					'cds--list-box__menu-item--active': item.selected,
					'cds--list-box__menu-item--highlighted': highlightedItem === getItemId(i)
				}">
				<div
					#listItem
					tabindex="-1"
					class="cds--list-box__menu-item__option">
					<div
						*ngIf="!listTpl && type === 'multi'"
						class="cds--form-item cds--checkbox-wrapper">
						<label
							[attr.data-contained-checkbox-state]="item.selected"
							class="cds--checkbox-label">
							<input
								class="cds--checkbox"
								type="checkbox"
								[checked]="item.selected"
								[disabled]="item.disabled"
								tabindex="-1">
							<span class="cds--checkbox-appearance"></span>
							<span class="cds--checkbox-label-text">{{item.content}}</span>
						</label>
					</div>
					<ng-container *ngIf="!listTpl && type === 'single'">{{item.content}}</ng-container>
					<svg
						*ngIf="!listTpl && type === 'single'"
						cdsIcon="checkmark"
						size="16"
						class="cds--list-box__menu-item__selected-icon">
					</svg>
					<ng-template
						*ngIf="listTpl"
						[ngTemplateOutletContext]="{item: item}"
						[ngTemplateOutlet]="listTpl">
					</ng-template>
				</div>
			</li>
		</ul>`,
      providers: [{
        provide: AbstractDropdownView,
        useExisting: DropdownList
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1$1.I18n
    }, {
      type: i0.ApplicationRef
    }];
  }, {
    ariaLabel: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    listTpl: [{
      type: Input
    }],
    select: [{
      type: Output
    }],
    scroll: [{
      type: Output
    }],
    blurIntent: [{
      type: Output
    }],
    list: [{
      type: ViewChild,
      args: ["list", {
        static: true
      }]
    }],
    type: [{
      type: Input
    }],
    showTitles: [{
      type: Input
    }],
    listElementList: [{
      type: ViewChildren,
      args: ["listItem"]
    }]
  });
})();
class ScrollableList {
  constructor(elementRef) {
    this.elementRef = elementRef;
    /**
     * Optional target list to scroll
     */
    this.nScrollableList = null;
    /**
     * Enables or disables scrolling for the whole directive
     */
    this.scrollEnabled = true;
    /**
     * How many lines to scroll by each time `wheel` fires
     * Defaults to 10 - based on testing this isn't too fast or slow on any platform
     */
    this.scrollBy = 10;
    this.canScrollUp = false;
    this.canScrollDown = false;
    this.list = this.elementRef.nativeElement;
  }
  ngOnChanges(changes) {
    if (changes.scrollEnabled) {
      if (changes.scrollEnabled.currentValue) {
        this.list.style.overflow = "hidden";
        this.scrollUpTarget.style.display = "flex";
        this.scrollDownTarget.style.display = "flex";
        this.canScrollUp = true;
        this.canScrollDown = true;
        this.updateScrollHeight();
        this.checkScrollArrows();
        setTimeout(() => {
          this.checkScrollArrows();
        });
      } else {
        this.scrollUpTarget.style.display = "none";
        this.scrollDownTarget.style.display = "none";
        this.canScrollUp = false;
        this.canScrollDown = false;
        this.list.style.height = null;
        this.list.style.overflow = null;
        clearInterval(this.hoverScrollInterval);
      }
    }
  }
  ngAfterViewInit() {
    if (this.nScrollableList) {
      this.list = this.elementRef.nativeElement.querySelector(this.nScrollableList);
    }
    this.scrollUpTarget.addEventListener("mouseover", () => this.onHoverUp(true));
    this.scrollUpTarget.addEventListener("mouseout", () => this.onHoverUp(false));
    this.scrollDownTarget.addEventListener("mouseover", () => this.onHoverDown(true));
    this.scrollDownTarget.addEventListener("mouseout", () => this.onHoverDown(false));
  }
  updateScrollHeight() {
    if (this.scrollEnabled) {
      const container = this.elementRef.nativeElement.parentElement;
      const containerRect = container.getBoundingClientRect();
      const innerHeightDiff = this.list.getBoundingClientRect().top - containerRect.top;
      const outerHeightDiff = containerRect.height - (containerRect.bottom - window.innerHeight);
      // 40 gives us some padding between the bottom of the list,
      // the bottom of the window, and the scroll down button
      const height = outerHeightDiff - innerHeightDiff - 40;
      this.list.style.height = `${height}px`;
    }
  }
  checkScrollArrows() {
    const scrollUpHeight = this.scrollUpTarget.offsetHeight;
    const scrollDownHeight = this.scrollDownTarget.offsetHeight;
    if (this.list.scrollTop === 0) {
      if (this.canScrollUp) {
        this.list.style.height = `${parseInt(this.list.style.height, 10) + scrollUpHeight}px`;
      }
      this.scrollUpTarget.style.display = "none";
      this.canScrollUp = false;
    } else if (this.list.scrollTop === this.list.scrollTopMax) {
      if (this.canScrollDown) {
        this.list.style.height = `${parseInt(this.list.style.height, 10) + scrollDownHeight}px`;
      }
      this.scrollDownTarget.style.display = "none";
      this.canScrollDown = false;
    } else {
      if (!this.canScrollUp) {
        this.list.style.height = `${parseInt(this.list.style.height, 10) - scrollUpHeight}px`;
      }
      if (!this.canScrollDown) {
        this.list.style.height = `${parseInt(this.list.style.height, 10) - scrollDownHeight}px`;
      }
      this.scrollUpTarget.style.display = "flex";
      this.scrollDownTarget.style.display = "flex";
      this.canScrollUp = true;
      this.canScrollDown = true;
    }
  }
  onWheel(event) {
    if (event.deltaY < 0) {
      this.list.scrollTop -= this.scrollBy;
    } else {
      this.list.scrollTop += this.scrollBy;
    }
    // only prevent the parent/window from scrolling if we can scroll
    if (!(this.list.scrollTop === this.list.scrollTopMax || this.list.scrollTop === 0)) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.checkScrollArrows();
  }
  onTouchStart(event) {
    if (event.touches[0]) {
      this.lastTouch = event.touches[0].clientY;
    }
  }
  onTouchMove(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.touches[0]) {
      const touch = event.touches[0];
      this.list.scrollTop += this.lastTouch - touch.clientY;
      this.lastTouch = touch.clientY;
      this.checkScrollArrows();
    }
  }
  hoverScrollBy(hovering, amount) {
    if (hovering) {
      this.hoverScrollInterval = setInterval(() => {
        this.list.scrollTop += amount;
        this.checkScrollArrows();
      }, 1);
    } else {
      clearInterval(this.hoverScrollInterval);
    }
  }
  onHoverUp(hovering) {
    // how many px/lines to scroll by on hover
    // 3 is just a random number that felt good
    // 1 and 2 are too slow, 4 works but it might be a tad fast
    this.hoverScrollBy(hovering, -3);
  }
  onHoverDown(hovering) {
    this.hoverScrollBy(hovering, 3);
  }
  onKeyDown(event) {
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      this.checkScrollArrows();
    }
  }
}
ScrollableList.ɵfac = function ScrollableList_Factory(t) {
  return new (t || ScrollableList)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
ScrollableList.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ScrollableList,
  selectors: [["", "cdsScrollableList", ""], ["", "ibmScrollableList", ""]],
  hostBindings: function ScrollableList_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("wheel", function ScrollableList_wheel_HostBindingHandler($event) {
        return ctx.onWheel($event);
      })("touchstart", function ScrollableList_touchstart_HostBindingHandler($event) {
        return ctx.onTouchStart($event);
      })("touchmove", function ScrollableList_touchmove_HostBindingHandler($event) {
        return ctx.onTouchMove($event);
      })("keydown", function ScrollableList_keydown_HostBindingHandler($event) {
        return ctx.onKeyDown($event);
      });
    }
  },
  inputs: {
    nScrollableList: "nScrollableList",
    scrollEnabled: "scrollEnabled",
    scrollUpTarget: "scrollUpTarget",
    scrollDownTarget: "scrollDownTarget",
    scrollBy: "scrollBy"
  },
  exportAs: ["scrollable-list"],
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollableList, [{
    type: Directive,
    args: [{
      selector: "[cdsScrollableList], [ibmScrollableList]",
      exportAs: "scrollable-list"
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    nScrollableList: [{
      type: Input
    }],
    scrollEnabled: [{
      type: Input
    }],
    scrollUpTarget: [{
      type: Input
    }],
    scrollDownTarget: [{
      type: Input
    }],
    scrollBy: [{
      type: Input
    }],
    onWheel: [{
      type: HostListener,
      args: ["wheel", ["$event"]]
    }],
    onTouchStart: [{
      type: HostListener,
      args: ["touchstart", ["$event"]]
    }],
    onTouchMove: [{
      type: HostListener,
      args: ["touchmove", ["$event"]]
    }],
    onKeyDown: [{
      type: HostListener,
      args: ["keydown", ["$event"]]
    }]
  });
})();
class DropdownModule {}
DropdownModule.ɵfac = function DropdownModule_Factory(t) {
  return new (t || DropdownModule)();
};
DropdownModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DropdownModule
});
DropdownModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [DropdownService],
  imports: [CommonModule, FormsModule, I18nModule, PlaceholderModule, UtilsModule, IconModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropdownModule, [{
    type: NgModule,
    args: [{
      declarations: [Dropdown, DropdownList, ScrollableList, AbstractDropdownView],
      exports: [Dropdown, DropdownList, ScrollableList, AbstractDropdownView],
      imports: [CommonModule, FormsModule, I18nModule, PlaceholderModule, UtilsModule, IconModule],
      providers: [DropdownService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractDropdownView, Dropdown, DropdownList, DropdownModule, DropdownService, ScrollableList };
