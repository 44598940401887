<!-- single file upload -->
<ng-container *ngIf="singleFile; else multi">
  <label class="label" [for]="inputId">{{
    label || "UPLOAD-FILE" | translate
  }}</label>
  <div
    class="single-file-input-upload"
    [ngClass]="{ disabled, 'fade-in-out': loading, invalid }"
  >
    <span class="placeholder truncate" [ngClass]="{ fulfilled: files[0] }">
      {{
        (loading
          ? ("UPLOADING" | translate) + files[0]?.name
          : files[0]?.name || placeholder || "UPLOAD-FILE"
        ) | translate
      }}
    </span>
    <label class="upload-btn" [for]="inputId">
      <span>{{
        (files[0] ? "UPLOAD-NEW-FILE" : "UPLOAD-FILE") | translate
      }}</span>
      <input
        [id]="inputId"
        type="file"
        accept="image/*,application/pdf"
        [multiple]="!singleFile"
        (change)="onFileSelected($event)"
        (cancel)="markAsTouched()"
      />
    </label>
  </div>
  <p *ngIf="!invalid && !hideCaption" class="txt-caption input-caption">
    {{ "UPLOAD-INPUT-CAPTION" | translate }}
  </p>
  <p *ngIf="invalid" class="txt-caption input-caption txt-caption-danger">
    {{ ("FORM-ERRORS.REQUIRED" || invalidTxt) | translate }}
  </p>
</ng-container>

<!-- multiple file upload -->
<ng-template #multi>
  <label
    *ngIf="label"
    [for]="inputId"
    class="txt-caption txt-caption-black label--multi"
    >{{ label | translate }}</label
  >
  <p *ngIf="!hideCaption" class="txt-caption caption--multi">
    {{ "UPLOAD-INPUT-CAPTION" | translate }}
  </p>
  <label class="upload-rounded-btn" [for]="inputId">
    <button cdsButton size="sm" class="btn-rounded">
      {{ buttonLabel | translate }}<i class="fi-plus-circle"></i>
    </button>
    <input
      #fileInput
      [id]="inputId"
      type="file"
      accept=".png,.jpg,.jpeg,.pdf"
      [multiple]="!singleFile"
      (change)="onFileSelected($event)"
      (click)="resetInputCachedValue()"
    />
  </label>
  <p *ngIf="loading" class="txt-caption">loading...</p>
  <div
    *ngFor="let file of uploadedFiles; let i = index"
    class="file-preview"
    [ngClass]="{ disabled, 'fade-in-out': loading }"
  >
    <p class="truncate">{{ file.file.name }}</p>
    <i class="fi-trash" (click)="deleteFile(file.file)"></i>
  </div>
  <!-- current file list incase of editing a form -->
  <div
    *ngFor="let file of ghostFiles; let i = index"
    class="file-preview"
    [ngClass]="{ disabled, 'fade-in-out': loading }"
  >
    <p class="truncate">{{ file.file_name }}</p>
    <i class="fi-trash" (click)="deleteGhostFile(file, i)"></i>
  </div>
</ng-template>
