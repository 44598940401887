<ng-container *ngIf="projectSteps.length">
  <ol class="steps">
    <div class="progress"></div>
    <div
      class="progress-percentage"
      [ngStyle]="{ height: 'calc(' + getProgressPercentage() + '% - 20px)' }"
    ></div>
    <li
      class="step flx flx-align-c gap-8"
      *ngFor="let step of projectSteps; let i = index"
      [ngClass]="{
        inactive: !isActiveStep(i) || !step.next_action
      }"
    >
      <div class="step-content flx-align-c flx-sb flx-1">
        <div class="step-info flx-col gap-4">
          <span class="fw-500">{{ step.action }}</span>
          <span class="txt-caption txt-xs fw-400">{{ step.timestamp }}</span>
        </div>
        <div
          *ngIf="step.next_action"
          class="next-action flx-align-c gap-4 txt-caption fw-500"
          (click)="performAction(step, i)"
        >
          <span class="link">{{ step.next_action.name }}</span>
          <i *ngIf="isActiveStep(i)" class="fi-arrow-left reverse"></i>
        </div>
      </div>
    </li>
  </ol>
</ng-container>
