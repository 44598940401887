<div class="page-container">
  <img
    class="illustration"
    [src]="
      isCustomer
        ? '../../../../../assets/svg/no-orders-illustration.svg'
        : '../../../../../assets/svg/no-payments-illustration.svg'
    "
    alt="No orders yet"
  />
  <app-heading
    [title]="isCustomer ? 'HOME.HEADER' : 'HOME.HEADER-2'"
    [caption]="isCustomer ? 'HOME.CAPTION' : 'HOME.CAPTION-2'"
    size="md"
  ></app-heading>

  <a
    *ngIf="isCustomer"
    cdsButton
    class="btn-lg btn-full-width"
    size="md"
    routerLink="/requests/new"
  >
    {{ "HOME.NEW-ORDER" | translate }}
  </a>
</div>
